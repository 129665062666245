import { radioClasses, Theme } from "@mui/material"

const Radio: NonNullable<Theme["components"]>["MuiRadio"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      color: theme.palette.primary.contrastText,

      [`&.${radioClasses.checked}`]: {
        "span:after": {
          ...(ownerState.disabled
            ? {
                backgroundColor: theme.palette.primary.contrastText,
              }
            : // @ts-ignore
              { backgroundColor: `${theme.palette[ownerState.color].main}` }),
        },
      },
    }),
  },
}

export default Radio
