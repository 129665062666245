import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const Profile = lazy(() => import("@/pages/Profile/Profile"))

const route: RouteObject = {
  path: "/profile",
  element: <Profile />,
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle.profilePage", {
        ns: "organisation",
      })} - ${i18next.t("label.hitseeker", { ns: "common" })}`,
    title: () =>
      i18next.t("pageTitle.profilePage", {
        ns: "organisation",
      }),
    crumb: () =>
      i18next.t("pageTitle.profilePage", {
        ns: "organisation",
      }),
  },
}

export default route
