import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import { store } from "@/app/store"
import { GetCpiTestTitle } from "@/features/cpiTest/cpiTest"
import i18next from "i18next"

const Statistics = lazy(
  () => import("@/pages/Games/CPI/Statistics/ViewStatistics"),
)

const route: RouteObject = {
  path: "/games/cpi-test/:gameId/:cpiTestId/:campaignId",
  handle: {
    crumb: (data: string) =>
      data ?? i18next.t("label.cpiInsights", { ns: "cpiTest" }),
  },
  loader: async ({ params }: LoaderFunctionArgs) => {
    let title = null
    if (!isNaN(params.cpiTestId)) {
      const parsedParams = { id: parseInt(params.cpiTestId) }
      const getter = store.dispatch(GetCpiTestTitle.initiate(parsedParams))
      await getter
      const response = GetCpiTestTitle.select(parsedParams)(store.getState())

      if (
        (response.data?.cpi?.length ?? 0) > 0 &&
        response.data!.cpi![0].name
      ) {
        title = response.data!.cpi![0].name

        getter.unsubscribe()
      }
      return title
    }
  },
  element: <Statistics />,
}

export default route
