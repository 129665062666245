import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const Home = lazy(() => import("@/pages/Home/Home"))

const route: RouteObject = {
  path: "/",
  element: <Home />,
  handle: {
    title: () => i18next.t("pageTitle", { ns: "dashboard" }),
  },
}

export default route
