import { SUPPORTED_LANGUAGES, SupportedLanguagesType } from "@/constants/i18n"

export const getCurrentLocale = (): SupportedLanguagesType | void => {
  const allowedLocales = new Set(SUPPORTED_LANGUAGES)

  const path = window.location.pathname
  const pathLocale = path.split("/")[1] as SupportedLanguagesType

  return allowedLocales.has(pathLocale) ? pathLocale : undefined
}

export const getPathWithLocale = () => {
  const locale = getCurrentLocale()
  let { pathname } = window.location

  if (locale) {
    pathname = pathname.split("/").slice(2).join("/")
  }

  if (pathname && pathname.charAt(0) !== "/") {
    pathname = "/" + pathname
  }

  return pathname
}

export const checkCurrentLocale = (locale: SupportedLanguagesType) =>
  locale === getCurrentLocale()

export const addLocaleToUrl = (url: string) => {
  const locale = getCurrentLocale() ?? "en-GB"
  const urlObj = new URL(url)

  return `${urlObj.origin}/${locale}${urlObj.pathname}`
}
