import baseTheme from "@/theme/variant/base"
import { createTheme, ThemeOptions } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const dark = createTheme(
  merge(cloneDeep(baseTheme), {
    palette: {
      mode: "dark",
      theme: "dark",
      background: {
        default: "#1E1E1E",
        paper: "#19191A",
        card: "#282828",
      },
      tertiary: { light: "#454545" },
      bgSvgShade: {
        startColor: "rgba(30, 30, 30, 0.2)",
        stopColor: "rgba(255, 255, 255, 0.05)",
      },
    },
  } as ThemeOptions),
)

export default dark
