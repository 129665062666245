import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import { store } from "@/app/store"
import { GetGameTitle } from "@/features/game/Game"

const GameKeyIndex = lazy(() => import("@/pages/Games/GameKeys/GameKeyIndex"))

const route: RouteObject = {
  path: "/games/:gameId/game-key",
  handle: {
    crumb: (data: string) => data ?? "Game Key",
  },
  loader: async ({ params }: LoaderFunctionArgs) => {
    let title = null
    if (!isNaN(params.gameId)) {
      const parsedParams = { id: parseInt(params.gameId) }
      const getter = store.dispatch(GetGameTitle.initiate(parsedParams))
      await getter
      const response = GetGameTitle.select(parsedParams)(store.getState())

      if (
        (response.data?.game?.length ?? 0) > 0 &&
        response.data!.game![0].name
      ) {
        title = response.data!.game![0].name

        getter.unsubscribe()
      }
      return title
    }
  },
  element: <GameKeyIndex />,
}
export default route
