import { PropsWithChildren, useLayoutEffect } from "react"
import { useMatches } from "react-router-dom"

const PageTitleProvider = ({ children }: PropsWithChildren) => {
  const matches = useMatches()

  useLayoutEffect(() => {
    const reversedMatches = [...matches].reverse()
    const lastMatch = reversedMatches.find((match) => !!match.handle?.pageTitle)

    if (lastMatch) {
      document.title = lastMatch.handle?.pageTitle?.() ?? "Hitseeker"
    }
  }, [matches])

  return children
}

export default PageTitleProvider
