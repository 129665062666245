import { GetGameFeedbackChatQuery, api as generatedApi } from "./Game.generated"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    "Game",
    "FeedbackAreas",
    "FeedbackThread",
    "FeedbackChat",
    "GameList",
    "GameStages",
  ],
  endpoints: {
    GetGame: {
      providesTags: () => [{ type: "Game", id: "LIST" }],
    },

    GetFeedbackGamesList: {
      providesTags: () => [{ type: "Game", id: "LIST" }],
    },

    GetGameDetails: {
      providesTags: (_, __, arg) => [{ type: "Game", id: arg.id }],
    },

    GetGamesCount: {
      providesTags: () => [{ type: "Game", id: "COUNT" }],
    },

    GetLatestGames: {
      providesTags: () => [{ type: "Game", id: "LIST" }],
    },

    GetGameFeedbackAreas: {
      providesTags: () => [{ type: "FeedbackAreas", id: "LIST" }],
    },

    GetGameFeedbackThreads: {
      providesTags: () => [{ type: "FeedbackThread", id: "LIST" }],
    },

    GetGameFeedbackChat: {
      providesTags: () => [{ type: "FeedbackChat", id: "LIST" }],
      serializeQueryArgs: ({ queryArgs }) => {
        const { page_no, created_at_range, page_size, ...restArgs } = queryArgs

        return { restArgs }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      merge: (cachedData, newData, { arg }) => {
        const feedbackChatItemIdMap: Record<string, boolean> = {}
        const mergedData = [
          ...(newData.feedback_chat_item ?? []),
          ...(cachedData.feedback_chat_item ?? []).filter(
            (chat) => chat.thread_id === arg.thread_id,
          ),
        ]

        const uniqueFeedbackChats = mergedData.filter((chat) => {
          if (!feedbackChatItemIdMap[chat.id]) {
            feedbackChatItemIdMap[chat.id] = true
            return true
          }

          return false
        })

        return {
          feedback_chat_item: uniqueFeedbackChats,
          hasMore: newData.feedback_chat_item?.length,
        }
      },
      transformResponse: (res: GetGameFeedbackChatQuery) => {
        return {
          feedback_chat_item: (res.feedback_chat_item ?? []).toReversed(),
        }
      },
    },

    GetGameTitle: {
      providesTags: (result, error, arg) => [{ type: "Game", id: arg.id }],
    },
    GetGameListFilter: {
      providesTags: () => [{ type: "GameList", id: "LIST" }],
    },

    GetGameTags: {
      providesTags: () => [{ type: "Game", id: "TAGS" }],
    },

    GetGameStages: {
      providesTags: () => [{ type: "GameStages", id: "LIST" }],
    },

    GetGamesGridList: {
      providesTags: () => [{ type: "Game", id: "GRID" }],
      forceRefetch: () => true,
    },

    GetGameWithByteBrew: {
      providesTags: () => [{ type: "Game", id: "BYTEBREW_GAMES" }],
    },
  },
})

export const {
  useGetGameQuery,
  useLazyGetGameQuery,
  useGetFeedbackGamesListQuery,
  useGetGameDetailsQuery,
  useGetLatestGamesQuery,
  useGetGamesCountQuery,
  useGetGameFeedbackAreasQuery,
  useGetGameFeedbackThreadsQuery,
  useLazyGetGameFeedbackChatQuery,
  useGetGameTitleQuery,
  useGetGameTagsQuery,
  useGetGameListFilterQuery,
  useGetGameStagesQuery,
  useGetGamesGridListQuery,
  useGetGameWithByteBrewQuery,
} = api

export const {
  GetGame,
  GetFeedbackGamesList,
  GetGameDetails,
  GetGamesCount,
  GetLatestGames,
  GetGameFeedbackAreas,
  GetGameFeedbackThreads,
  GetGameFeedbackChat,
  GetGameTitle,
  GetGameTags,
} = api.endpoints
