import SomethingWentWrongImage from "@/assets/SomethingWrong.svg?react"
import NewReleaseFrameIcon from "@/assets/new-build-frame.svg?react"
import { Box, Button, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"

const SomethingWentWrong: FC = () => {
  const { t } = useTranslation("common")
  const error = useRouteError()

  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        {error
          ?.toString()
          .includes("Failed to fetch dynamically imported module") ||
        error?.toString().includes("Importing a module script failed") ? (
          <>
            <NewReleaseFrameIcon />
            <Typography variant="h3" color="primary">
              {t("label.newVersion")}
            </Typography>
            <Typography variant="body1">{t("label.newVersionInfo")}</Typography>
            <Button
              sx={{ mt: 2 }}
              color="primary"
              onClick={() => window.location.reload()}
            >
              {t("button.refresh")}
            </Button>
          </>
        ) : (
          <>
            <SomethingWentWrongImage />
            <Typography variant="h3" color="primary">
              {t("label.error")}
            </Typography>
            <Typography variant="body1">
              {t("label.somethingWentWrong")}
            </Typography>
            <Button sx={{ mt: 2 }} color="primary" href="/">
              {t("button.goBackToHome")}
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default SomethingWentWrong
