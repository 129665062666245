import { Theme } from "@mui/material"

const IconButton: NonNullable<Theme["components"]>["MuiIconButton"] = {
  defaultProps: { disableRipple: true },
  styleOverrides: {
    root: ({ theme }) => ({
      svg: {
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    }),
  },
}

export default IconButton
