import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const KwaleeFeedback = lazy(() => import("@/pages/Games/Feedback/Feedback"))

const route: RouteObject = {
  path: "/games/feedback",
  element: <KwaleeFeedback />,
  handle: {
    title: () => i18next.t("pageTitle", { ns: "feedback" }),
    crumb: () => i18next.t("pageTitle", { ns: "feedback" }),
  },
}

export default route
