import { api as restApi } from "@/app/services/rest/api"
import { api as graphApi } from "@/app/services/graphql/api"
import { RootState } from "@/app/store"
import {
  authenticate,
  changePassword,
  confirmForgotPassword,
  forgotPassword,
  getTokens,
  refreshAuth,
  register as cognitoRegister,
  Scope as CognitoScope,
  UTMData,
} from "@/features/auth/cognito"
import { setTheme } from "@/features/general/generalSlice"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice"
import {
  AuthenticationResultType,
  NotAuthorizedException,
  SignUpCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider"
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { callInternalCognitoRegister } from "@/util/permissions"
import i18next from "i18next"
import ReactGA from "react-ga4"
import { HITSEEKER_EVENT } from "@/util/hitseekerEvents"

type State = {
  id: string | null
  email: string | null
  isRegistering: boolean
  userType: string | null
  isAuthenticating: boolean
  scope: CognitoScope | null
  permissionModules: string[]
  loginProvider: "email" | "sso" | null
  authenticationResult: AuthenticationResultType | null
}

type LoggedInUser = {
  id: State["id"]
  userType: State["userType"]
  permissionsModules: State["permissionModules"]
}

const initialState: State = {
  id: null,
  email: null,
  scope: null,
  userType: null,
  loginProvider: null,
  isRegistering: false,
  permissionModules: [],
  isAuthenticating: false,
  authenticationResult: null,
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload
    },
    setScope: (state, action: PayloadAction<CognitoScope | null>) => {
      state.scope = action.payload
    },
    setLoginProvider: (
      state,
      action: PayloadAction<State["loginProvider"]>,
    ) => {
      state.loginProvider = action.payload
    },
    setLoggedInUser: (state, action: PayloadAction<LoggedInUser>) => {
      state.id = action.payload.id
      state.userType = action.payload.userType
      state.permissionModules = action.payload.permissionsModules
    },
    handleLogin: (state, action: PayloadAction<AuthenticationResultType>) => {
      state.authenticationResult = action.payload
    },
    handleRefresh: (state, action: PayloadAction<AuthenticationResultType>) => {
      state.authenticationResult = {
        ...state.authenticationResult,
        ...action.payload,
      }
    },
    handleLogout: (state) => {
      state.email = null
      state.scope = null
      state.authenticationResult = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isRegistering = true
    })

    builder.addCase(refresh.fulfilled, (state, action) => {
      state.isAuthenticating = false

      state.authenticationResult = {
        ...state.authenticationResult,
        ...action.payload,
      }
    })

    builder.addMatcher(
      isAnyOf(login.fulfilled, googleAuth.fulfilled),
      (state, action) => {
        state.isAuthenticating = false
        state.authenticationResult = action.payload
      },
    )

    builder.addMatcher(
      isAnyOf(login.pending, refresh.pending, googleAuth.pending),
      (state) => {
        state.isAuthenticating = true
      },
    )

    builder.addMatcher(
      isAnyOf(login.rejected, refresh.rejected, googleAuth.rejected),
      (state) => {
        state.isAuthenticating = false
      },
    )

    builder.addMatcher(
      isAnyOf(register.fulfilled, register.rejected),
      (state) => {
        state.isRegistering = false
      },
    )
  },
})

export const login = createAsyncThunk<
  AuthenticationResultType,
  { email: string; password: string }
>(
  `${slice.name}/login`,
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await authenticate(email, password)
      if (!response.AuthenticationResult) {
        return rejectWithValue("No AuthenticationResult")
      }

      dispatch(setEmail(email))
      dispatch(setScope("external"))
      dispatch(setLoginProvider("email"))
      dispatch(slice.actions.handleLogin(response.AuthenticationResult))

      dispatch(
        enqueueSnackbar({
          message: i18next.t("notifier.loginSuccess", { ns: "onboarding" }),
          options: {
            key: "login_success",
            variant: "success",
            autoHideDuration: 2000,
          },
        }),
      )

      return response.AuthenticationResult
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        return rejectWithValue(e.message)
      }
    }

    return rejectWithValue("Unknown error")
  },
)

export const refresh = createAsyncThunk<
  AuthenticationResultType,
  { token: string }
>(
  `${slice.name}/refresh`,
  async ({ token }, { dispatch, rejectWithValue, getState }) => {
    try {
      const scope = (getState() as RootState).auth.scope!
      const response = await refreshAuth(token, scope)
      if (!response.AuthenticationResult) {
        return rejectWithValue("No AuthenticationResult")
      }

      dispatch(slice.actions.handleRefresh(response.AuthenticationResult))

      return response.AuthenticationResult
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        return rejectWithValue(e.message)
      }
    }

    return rejectWithValue("Unknown error")
  },
)

export const logout = createAsyncThunk<void, boolean | undefined>(
  `${slice.name}/logout`,
  async (shouldShowToast = true, { dispatch }) => {
    dispatch(slice.actions.handleLogout())
    dispatch(graphApi.util.resetApiState())
    dispatch(restApi.util.resetApiState())
    dispatch(setTheme(undefined))

    ReactGA.set({ userId: undefined })
    HITSEEKER_EVENT.logout()

    shouldShowToast &&
      dispatch(
        enqueueSnackbar({
          message: i18next.t("notifier.logoutSuccess", { ns: "onboarding" }),
          options: {
            key: "logout_success",
            variant: "success",
            autoHideDuration: 2000,
          },
        }),
      )
  },
)

export const register = createAsyncThunk<
  SignUpCommandOutput,
  { email: string; password: string; isInvited?: boolean; utm: UTMData }
>(
  `${slice.name}/register`,
  async ({ email, password, isInvited = false, utm }, { rejectWithValue }) => {
    try {
      return await cognitoRegister(email, password, isInvited, utm)
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        return rejectWithValue(e.message)
      }
    }

    return rejectWithValue("Unknown error")
  },
)

export const googleAuth = createAsyncThunk<
  AuthenticationResultType,
  { code: string; scope: CognitoScope }
>(
  `${slice.name}/googleAuth`,
  async ({ code, scope }, { dispatch, rejectWithValue }) => {
    try {
      const tokens = await getTokens(code, scope)
      if (!tokens.access_token) {
        return rejectWithValue("Invalid token")
      }

      if (scope === "internal") {
        const response = await callInternalCognitoRegister(
          tokens.access_token,
          tokens.id_token,
        )

        if (response.status === 401) {
          return rejectWithValue(
            i18next.t("notifier.notAuthorised", { ns: "common" }),
          )
        }
      }

      const authenticationResult = {
        AccessToken: tokens.access_token,
        RefreshToken: tokens.refresh_token,
        ExpiresIn: tokens.expires_in,
        TokenType: tokens.token_type,
        IdToken: tokens.id_token,
      }

      if (authenticationResult.IdToken) {
        const b64 = authenticationResult.IdToken.slice(
          authenticationResult.IdToken.indexOf(".") + 1,
          authenticationResult.IdToken.lastIndexOf("."),
        )
        const info = JSON.parse(atob(b64))
        dispatch(setEmail(info.email))
      }

      dispatch(setScope(scope))
      dispatch(setLoginProvider("sso"))
      dispatch(slice.actions.handleLogin(authenticationResult))

      dispatch(
        enqueueSnackbar({
          message: i18next.t("notifier.loginSuccess", { ns: "onboarding" }),
          options: {
            key: "login_success",
            variant: "success",
          },
        }),
      )

      return authenticationResult
    } catch (e) {
      console.error(e)

      if (e instanceof Error) {
        return rejectWithValue(e.message)
      }
    }

    return rejectWithValue("Unknown error")
  },
)

export const updatePassword = createAsyncThunk<
  void,
  { currPassword: string; newPassword: string; hasRefreshed?: boolean }
>(
  `${slice.name}/updatePassword`,
  async (
    { currPassword, newPassword, hasRefreshed = false },
    { dispatch, rejectWithValue, getState },
  ) => {
    const authenticationResult = (getState() as RootState).auth
      .authenticationResult!
    try {
      await changePassword(
        authenticationResult.AccessToken!,
        currPassword,
        newPassword,
      )

      return
    } catch (e) {
      console.error(e)

      if (
        e instanceof NotAuthorizedException &&
        e.message === "Access Token has expired"
      ) {
        if (hasRefreshed) return rejectWithValue(e.message)

        await dispatch(refresh({ token: authenticationResult.RefreshToken! }))
        await dispatch(
          updatePassword({ currPassword, newPassword, hasRefreshed: true }),
        )

        return
      }

      const err = e as Error

      return rejectWithValue(err?.message ?? "Unknown error")
    }
  },
)

export const forgotenPassword = createAsyncThunk<void, { email: string }>(
  `${slice.name}/forgotenPassword`,
  async ({ email }, { rejectWithValue }) => {
    try {
      await forgotPassword(email)

      return
    } catch (e) {
      console.error(e)
      const err = e as Error

      return rejectWithValue(err?.message ?? "Unknown error")
    }
  },
)

export const resetPassword = createAsyncThunk<
  void,
  { email: string; password: string; code: string }
>(
  `${slice.name}/resetPassword`,
  async ({ email, password, code }, { rejectWithValue }) => {
    try {
      await confirmForgotPassword(email, password, code)

      return
    } catch (e) {
      console.error(e)
      const err = e as Error

      return rejectWithValue(err?.message ?? "Unknown error")
    }
  },
)

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
}

const { reducer } = slice
export default persistReducer(persistConfig, reducer)

export const {
  handleLogout,
  setEmail,
  setScope,
  setLoginProvider,
  setLoggedInUser,
} = slice.actions

export const selectEmail = (state: RootState) => state.auth.email

export const selectIsAuthenticating = (state: RootState) =>
  state.auth.isAuthenticating

export const selectIsAuthenticated = (state: RootState) =>
  !!state.auth.authenticationResult

export const selectIsRegistering = (state: RootState) =>
  state.auth.isRegistering

export const selectExpiresIn = (state: RootState) =>
  state.auth.authenticationResult?.ExpiresIn
    ? new Date(state.auth.authenticationResult.ExpiresIn)
    : null
export const selectHasTokenExpired = (state: RootState) => {
  const expiresIn = selectExpiresIn(state)
  return !expiresIn || expiresIn.getTime() < Date.now()
}

export const selectLoginProvider = (state: RootState) =>
  state.auth.loginProvider
