import graphqlBaseQuery from "@/app/services/graphql/api/graphqlBaseQuery"
import withTokenRefresh from "@/app/services/graphql/api/withTokenRefresh"
import { createApi } from "@reduxjs/toolkit/query/react"

export const TAGS = [
  "Category",
  "Article",
  "FurtherReading",
  "MainFolderId",
  "Folder",
  "FolderAndFile",
  "STORAGE_SPACE",
  "File",
  "Thread",
  "Priority",
  "CategoryAndPriority",
  "ThreadList",
  "ThreadChat",
  "SearchFolderAndFile",
  "AdminRootFolderList",
  "User",
  "Organisation",
  "Game",
  "Permission",
  "FeedbackAreas",
  "FeedbackThread",
  "FeedbackChat",
  "Notification",
  "UnReadNotificationCount",
  "GameList",
  "CpiList",
  "RequestChangesList",
  "CpiVideos",
  "GameStages",
  "FbAdAccount",
  "CustomEvent",
  "CustomEventSubParameter",
  "CustomEventGames",
] as const

export const api = createApi({
  reducerPath: "graphApi",
  baseQuery: withTokenRefresh(graphqlBaseQuery),
  tagTypes: TAGS,
  endpoints: () => ({}),
})
