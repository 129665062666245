import mixpanel, { Config, Dict } from "mixpanel-browser"

export const HITSEEKER_EVENT_KEYS = {
  login: "Login",
  logout: "Logout",
  register: "Register",
  forgotPassword: "Forgot Password",
  organisationCustomised: "Organisation Customised",
  notificationsOpened: "Notifications Opened",
  clickedCompleteProfileOnHome: "Clicked Complete Profile on Home",
  clickedMyGamesOnHome: "Clicked My Games on Home",
  clickedResumeGameSubmissionOnHome: "Clicked Resume Game Submission on Home",
  clickedViewAllAcademyVideosOnHome: "Clicked View All Academy Videos on Home",
  clickedViewAllArticlesOnHome: "Clicked View All Articles on Home",
  clickedSubmitGameOnMyGames: "Clicked Submit Game on My Games",
  searchedOnMyGames: "Searched on My Games",
  viewedGameReport: "Clicked View Game Report on Game Card",
  requestedFeedback: "Clicked Request Feedback on Game Card",
  clickedInstallSdk: "Clicked Install SDK on Game Card",
  viewedDocumentsFromGame: "Clicked View Documents on Game Card",
  clickedGameTitleOnSubmissionForm:
    "Clicked Game Title on Game Submission Form",
  clickedShortInfoOnSubmissionForm:
    "Clicked Short Info on Game Submission Form",
  clickedGameIconOnSubmissionForm: "Clicked Game Icon on Game Submission Form",
  clickedAdditionalInformationOnSubmissionForm:
    "Clicked Additional Information on Game Submission Form",
  submittedGame: "Submitted Game",
  downloadedKwaleeSdk: "Clicked Download Kwalee SDK",
  validatedAppUrlOnGameSubmission:
    "Clicked Validate App URL on Game Submission",
  clickedCreateFolder: "Clicked Create Folder",
  createdFolder: "Created Folder",
  clickedViewAllFolders: "Clicked View All Folders on File Management",
  clickedViewAllFiles: "Clicked View All Files on File Management",
  clickedCreateNewThread: "Clicked Create New Thread",
  createdNewThread: "Created New Thread",
  viewedThread: "Clicked View Thread",
  searchedDiscussionForumThread: "Searched on Discussion Forum",
  viewedCategoriesOnDiscussionForum: "Filtered By Category on Discussion Forum",
  searchedKnowledgeBase: "Searched on Knowledge Base",
  clickedNextArticleKnowledgeBase: "Clicked on Next Article Knowledge Base",
  clickedPrevArticleKnowledgeBase: "Clicked on Previous Article Knowledge Base",
  clickedRequestCPIGamesCard: "Clicked Request CPI from Games Card",
  clickedRequestCPICPIList: "Clicked Request CPI from CPI List",
  clickedCPITestName: "Clicked CPI Test Name on CPI Form",
  validatedFacebookAppIdOnCPIForm: "Validated Facebook App ID on CPI Form",
  validatedAppUrlOnCPIForm: "Clicked Validate App URL on CPI Form",
  uploadedVideoOnCPIForm: "Uploaded Video on CPI Form",
  cpiSubmitted: "Submitted CPI",
  openedCpiReasonsOfRejection: "Opened CPI Reasons of Rejection",
} as const

type EventKeys =
  (typeof HITSEEKER_EVENT_KEYS)[keyof typeof HITSEEKER_EVENT_KEYS]

const isMxPannelKeyPresent = !!import.meta.env.VITE_MIXPANEL_TOKEN

export const HITSEEKER_EVENT = {
  init(token: string, config: Partial<Config>) {
    try {
      if (isMxPannelKeyPresent) {
        mixpanel.init(token, config)
      }
    } catch (e) {
      console.error(e)
    }
  },
  send(eventKey: EventKeys, properties?: Dict) {
    try {
      if (isMxPannelKeyPresent) {
        mixpanel.track(eventKey, properties)
      }
    } catch (e) {
      console.error(e)
    }
  },
  identify(id: string, name: string, email: string) {
    try {
      if (isMxPannelKeyPresent) {
        mixpanel.identify(id)

        mixpanel.people.set({
          $name: name,
          $email: email,
        })
      }
    } catch (e) {
      console.error(e)
    }
  },
  logout() {
    try {
      if (isMxPannelKeyPresent) {
        mixpanel.track(HITSEEKER_EVENT_KEYS.logout)
        mixpanel.reset()
      }
    } catch (e) {
      console.error(e)
    }
  },
}
