import { Theme } from "@mui/material"

const Paper: NonNullable<Theme["components"]>["MuiPaper"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...(!ownerState.square && {
        borderRadius: theme.spacing(1),
      }),
      ...(ownerState.color === "secondary" &&
        theme.palette.mode === "dark" && {
          backgroundColor: "#313131",
        }),
      ...(ownerState.color === "secondary" &&
        theme.palette.mode !== "dark" && {
          backgroundColor: "#FDFDFD",
        }),
    }),
  },
}

export default Paper
