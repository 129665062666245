import { SupportedLanguagesType } from "@/constants/i18n"
import { Theme } from "@mui/material"

const TableCell: NonNullable<Theme["components"]>["MuiTableCell"] = {}

const TableCellAr: NonNullable<Theme["components"]>["MuiTableCell"] = {
  styleOverrides: {
    root: () => ({
      fontSize: 16,
    }),
  },
}

const getTableCellOverrides = (
  locale: SupportedLanguagesType,
): NonNullable<Theme["components"]>["MuiTableCell"] => {
  switch (locale) {
    case "en-GB":
      return TableCell
    case "ar-SA":
      return TableCellAr
    default:
      return TableCell
  }
}

export default getTableCellOverrides
