import ProtectedRoute from "@/components/ProtectedRoute"
import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const MyOrganisation = lazy(
  () => import("@/pages/MyOrganisation/MyOrganisation"),
)

const route: RouteObject = {
  path: "/organisation",
  element: (
    <ProtectedRoute
      hasPermission={({ auth }) =>
        auth.userType === "external_admin" ||
        auth.permissionModules.includes("organisation")
      }
      redirectTo="/"
    >
      <MyOrganisation />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle.myOrganisationPage", {
        ns: "organisation",
      })} - ${i18next.t("label.hitseeker", { ns: "common" })}`,
    title: () =>
      i18next.t("pageTitle.myOrganisationPage", {
        ns: "organisation",
      }),
    crumb: () =>
      i18next.t("pageTitle.myOrganisationPage", {
        ns: "organisation",
      }),
  },
}

export default route
