import Button from "@/theme/variant/Button"
import Checkbox from "@/theme/variant/Checkbox"
import CssBaseline from "@/theme/variant/CssBaseline"
import IconButton from "@/theme/variant/IconButton"
import Paper from "@/theme/variant/Paper"
import Radio from "@/theme/variant/Radio"
import Select from "@/theme/variant/Select"
import Tabs, { Tab } from "@/theme/variant/Tabs"
import TextField from "@/theme/variant/TextField"
import TypographyEn from "@/theme/variant/TypographyEn"
import TypographyAr from "@/theme/variant/TypographyAr"
import { getCurrentLocale } from "@/util/i18n"
import { ThemeOptions } from "@mui/material"
import { CSSProperties } from "@mui/material/styles/createMixins"
import { SupportedLanguagesType } from "@/constants/i18n"
import { TypographyOptions } from "@mui/material/styles/createTypography"
import getTooltipOverrides from "./Tooltip"
import getTableCellOverrides from "./TableCell"
import getInputLabelOverrides from "./InputLabel"

const typography: Record<SupportedLanguagesType, TypographyOptions> = {
  "en-GB": TypographyEn,
  "ar-SA": TypographyAr,
}

const baseTheme: ThemeOptions = {
  palette: {
    mode: "light",
    theme: "light",
    primary: {
      main: "#FFCB05",
      contrastText: "#1E1E1E",
    },
    secondary: {
      main: "#1E1E1E",
      contrastText: "#F7F7F7",
    },
    tertiary: {
      main: "#E0E0E0",
      light: "#E8E8E8",
      dark: "#BDBDBD",
      contrastText: "#1E1E1E",
    },
    kwalee: {
      main: "#ffcd05",
      light: "#ffd844",
      dark: "#ffc400",
      contrastText: "#1E1E1E",
    },
    background: {
      default: "#F2F3F7",
      paper: "#FAF9FE",
      card: "#FFFFFF",
    },
    bgSvgShade: {
      startColor: "#FFCD05",
      stopColor: "#FAA51A",
    },
    ctaSvgShade: {
      startColor: "#FFCD05",
      stopColor: "#FAA51A",
    },
  },
  typography: typography[getCurrentLocale() ?? "en-GB"],
  components: {
    MuiButton: Button,
    MuiCheckbox: Checkbox,
    MuiCssBaseline: CssBaseline,
    MuiIconButton: IconButton,
    MuiPaper: Paper,
    MuiRadio: Radio,
    MuiSelect: Select,
    MuiTabs: Tabs,
    MuiTab: Tab,
    MuiTextField: TextField,
    MuiInputLabel: getInputLabelOverrides(getCurrentLocale() ?? "en-GB"),
    MuiTooltip: getTooltipOverrides(getCurrentLocale() ?? "en-GB"),
    MuiTableCell: getTableCellOverrides(getCurrentLocale() ?? "en-GB"),
  },
  mixins: {
    flex: ({
      justifyContent = "center",
      alignItems = "center",
      flexDirection = "row",
    }) =>
      ({
        display: "flex",
        justifyContent,
        alignItems,
        flexDirection,
      } as CSSProperties),
  },
}

export default baseTheme
