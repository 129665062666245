import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import i18next from "i18next"

const Analytics = lazy(() => import("@/pages/Analytics/Analytics"))
const route: RouteObject = {
  path: "/analytics",
  element: <Analytics />,
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle", { ns: "analytics" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    title: () => i18next.t("pageTitle", { ns: "analytics" }),
    crumb: () => i18next.t("pageTitle", { ns: "analytics" }),
  },
}
export default route
