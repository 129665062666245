import baseTheme from "@/theme/variant/base"
import { createTheme } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const orange = createTheme(
  merge(cloneDeep(baseTheme), {
    palette: {
      theme: "orange",
      primary: {
        main: "#FAA401",
        contrastText: "#1E1E1E",
      },
      bgSvgShade: {
        startColor: "#FAA51A",
        stopColor: "#FFCD05",
      },
      ctaSvgShade: {
        startColor: "#FAA51A",
        stopColor: "#FFCD05",
      },
    },
  }),
)

export default orange
