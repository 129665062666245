import { RootState, store } from "@/app/store"
import { Path, To } from "history"
import React from "react"
import { Navigate, useLocation } from "react-router-dom"

type Props = {
  hasPermission: boolean | ((state: RootState) => boolean)
  children: React.ReactNode
  redirectTo?: To
}

export default function ProtectedRoute({
  hasPermission,
  children,
  redirectTo,
}: Props) {
  const location = useLocation()

  let returnPath: string | Path = location
  if (location.pathname === "/login") {
    returnPath = "/"
  }

  const doesHavePermission =
    typeof hasPermission === "function"
      ? hasPermission(store.getState())
      : hasPermission

  if (!doesHavePermission) {
    return redirectTo ? (
      <Navigate to={redirectTo} />
    ) : (
      <Navigate to={"/login"} state={{ returnPath }} />
    )
  }

  return <>{children}</>
}
