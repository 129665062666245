import { api as graphqlApi } from "@/app/services/graphql/api"
import { api as restApi } from "@/app/services/rest/api"
import auth from "@/features/auth/authSlice"
import general from "@/features/general/generalSlice"
import notifier from "@/features/notifier/notifierSlice"
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist"

// Add the API handlers here to register them
const API_HANDLERS = [restApi, graphqlApi] as const

// Type mapping to extract the reducer type from the API handlers
const API_REDUCERS = Object.fromEntries(
  API_HANDLERS.map((api) => [api.reducerPath, api.reducer]),
) as ApiReducerMap

export const store = configureStore({
  reducer: {
    // Register the API reducers
    ...API_REDUCERS,
    // Register the other reducers
    auth,
    general,
    notifier,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      // Register the API middleware
      Object.values(API_HANDLERS).map((api) => api.middleware),
    ),
})

export const storePersistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Type helper for the API handlers
type ApiReducerMap = {
  [K in (typeof API_HANDLERS)[number]["reducerPath"]]: Extract<
    (typeof API_HANDLERS)[number],
    { reducerPath: K }
  >["reducer"]
}
