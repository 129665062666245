import { OAuthTokenResponse } from "@/model/response"
import { addLocaleToUrl, getCurrentLocale } from "@/util/i18n"
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  ChangePasswordCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  ResendConfirmationCodeCommand,
} from "@aws-sdk/client-cognito-identity-provider"

const externalClient = new CognitoIdentityProviderClient({
  region: import.meta.env.VITE_COGNITO_REGION,
})

const internalClient = new CognitoIdentityProviderClient({
  region: import.meta.env.VITE_COGNITO_INTERNAL_REGION,
})

type Provider = {
  client: CognitoIdentityProviderClient
  clientId: string
  redirectUri: string
  poolName: string
  region: string
}

export type UTMData = {
  source: string
  medium: string
  name: string
  term: string
  content: string
}

export type Scope = "internal" | "external"

const providers: Record<Scope, Provider> = {
  internal: {
    client: internalClient,
    clientId: import.meta.env.VITE_COGNITO_INTERNAL_CLIENT_ID,
    redirectUri: import.meta.env.VITE_COGNITO_INTERNAL_REDIRECT_URI,
    poolName: import.meta.env.VITE_COGNITO_INTERNAL_POOL_NAME,
    region: import.meta.env.VITE_COGNITO_INTERNAL_REGION,
  },
  external: {
    client: externalClient,
    clientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    redirectUri: addLocaleToUrl(import.meta.env.VITE_COGNITO_REDIRECT_URI),
    poolName: import.meta.env.VITE_COGNITO_POOL_NAME,
    region: import.meta.env.VITE_COGNITO_REGION,
  },
}

export async function authenticate(email: string, password: string) {
  const provider = providers.external

  const command = new InitiateAuthCommand({
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: provider.clientId,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
  })

  return provider.client.send(command)
}

export async function refreshAuth(token: string, scope: Scope) {
  const provider = providers[scope]

  const command = new InitiateAuthCommand({
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: provider.clientId,
    AuthParameters: {
      REFRESH_TOKEN: token,
    },
  })

  return provider.client.send(command)
}

export async function register(
  email: string,
  password: string,
  isInvited: boolean,
  utm: UTMData,
) {
  const provider = providers.external

  const command = new SignUpCommand({
    ClientId: provider.clientId,
    Username: email,
    Password: password,
    ClientMetadata: {
      "custom:is_invited": isInvited ? "1" : "0",
    },
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
      {
        Name: "custom:utm_campaign_source",
        Value: utm.source,
      },
      {
        Name: "custom:utm_campaign_medium",
        Value: utm.medium,
      },
      {
        Name: "custom:utm_campaign_name",
        Value: utm.name,
      },
      {
        Name: "custom:utm_campaign_term",
        Value: utm.term,
      },
      {
        Name: "custom:utm_campaign_content",
        Value: utm.content,
      },
      {
        Name: "custom:hitseeker_locale",
        Value: getCurrentLocale() ?? "en-GB",
      },
    ],
  })

  return provider.client.send(command)
}

export async function getTokens(code: string, scope: Scope) {
  const provider = providers[scope]

  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: provider.clientId,
    code,
    redirect_uri: provider.redirectUri,
  })

  const result = await fetch(
    `https://${provider.poolName}.auth.${
      provider.region
    }.amazoncognito.com/oauth2/token?${params.toString()}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    },
  )

  return (await result.json()) as OAuthTokenResponse
}

export async function verifyEmail(
  username: string,
  code: string,
  scope: Scope = "external",
) {
  const provider = providers[scope]

  const command = new ConfirmSignUpCommand({
    ClientId: provider.clientId,
    Username: username,
    ConfirmationCode: code,
  })

  return provider.client.send(command)
}

export async function changePassword(
  accessToken: string,
  currPassword: string,
  newPassword: string,
) {
  const provider = providers.external

  const command = new ChangePasswordCommand({
    AccessToken: accessToken,
    PreviousPassword: currPassword,
    ProposedPassword: newPassword,
  })

  return provider.client.send(command)
}

export async function forgotPassword(username: string) {
  const provider = providers.external

  const command = new ForgotPasswordCommand({
    ClientId: provider.clientId,
    Username: username,
  })

  return provider.client.send(command)
}

export async function confirmForgotPassword(
  username: string,
  password: string,
  code: string,
) {
  const provider = providers.external

  const command = new ConfirmForgotPasswordCommand({
    ClientId: provider.clientId,
    Username: username,
    Password: password,
    ConfirmationCode: code,
  })

  return provider.client.send(command)
}

export async function resendVerificationEmail(username: string) {
  const provider = providers.external

  const command = new ResendConfirmationCodeCommand({
    ClientId: provider.clientId,
    Username: username,
  })

  return provider.client.send(command)
}
