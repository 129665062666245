import { checkboxClasses, Theme } from "@mui/material"

const Checkbox: NonNullable<Theme["components"]>["MuiCheckbox"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      color: theme.palette.primary.contrastText,
      padding: 1,
      marginRight: 10,

      [`&.${checkboxClasses.checked}`]: {
        "span:after": {
          ...(ownerState.disabled
            ? {
                backgroundColor: theme.palette.primary.contrastText,
              }
            : // @ts-ignore
              { backgroundColor: `${theme.palette[ownerState.color].main}` }),
        },
      },
    }),
  },
}

export default Checkbox
