import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import { store } from "@/app/store"
import { GetFolderTitle } from "@/features/fileManangement/fileManagement"
import i18next from "i18next"

const FolderView = lazy(
  () => import("@/pages/FileManagement/Folder/FolderView"),
)

export type FolderParams = {
  folder: string
}
const route: RouteObject = {
  path: "/file-management/:folder",
  handle: {
    crumb: (data: string) => data ?? i18next.t("unKnownFolder"),
  },
  loader: async ({ params }: LoaderFunctionArgs<FolderParams>) => {
    let title = null
    if (!isNaN(params.folder)) {
      const parsedParams = { id: parseInt(params.folder) }
      const getter = store.dispatch(GetFolderTitle.initiate(parsedParams))
      await getter
      const response = GetFolderTitle.select(parsedParams)(store.getState())
      if (
        (response.data?.folder?.length ?? 0) > 0 &&
        response.data!.folder![0].name
      ) {
        title = response.data!.folder![0].name

        getter.unsubscribe()
      }
      return title
    }
  },
  element: <FolderView />,
}

export default route
