import { ThemeMode } from "@/theme"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { RootState } from "../../app/store"

type State = {
  navigationOpen: boolean
  theme: ThemeMode | undefined
}

const initialState: State = {
  navigationOpen: true,
  theme: undefined,
}

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setNavigationOpen: (state, action: PayloadAction<boolean>) => {
      state.navigationOpen = action.payload
    },
    setTheme: (state, action: PayloadAction<ThemeMode | undefined>) => {
      state.theme = action.payload
    },
  },
})

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
}

const { reducer } = slice
export default persistReducer(persistConfig, reducer)

export const { setNavigationOpen } = slice.actions

export const selectIsNavigationOpen = (state: RootState) =>
  state.general.navigationOpen

export const { setTheme } = slice.actions

export const selectTheme = (state: RootState) => state.general.theme
