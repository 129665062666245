import AppMaintenanceFrame from "@/assets/AppMaintenance.svg?react"
import HitSeekerLogo from "@/assets/HitseekerLogoPrimary.png"
import HitSeekerLogoDark from "@/assets/HitseekerLogoPrimaryDark.png"
import HitSeekerArLogo from "@/assets/HitseekerArLogo.png"
import HitSeekerArLogoDark from "@/assets/HitseekerArLogoDark.png"
import { Box, Button, Divider, Typography, useTheme } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Refresh } from "@mui/icons-material"

const AppMaintenance: FC = () => {
  const { t, i18n } = useTranslation("common")
  const lightModeLogo =
    i18n.language === "ar-SA" ? HitSeekerArLogo : HitSeekerLogo
  const darkModeLogo =
    i18n.language === "ar-SA" ? HitSeekerArLogoDark : HitSeekerLogoDark

  const theme = useTheme()

  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box width="100%" textAlign="center" pb={2} pt={5}>
        <img
          src={theme.palette.mode === "dark" ? darkModeLogo : lightModeLogo}
          alt="HitSeeker"
          width={180}
          height="auto"
        />
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        textAlign="center"
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
      >
        <AppMaintenanceFrame style={{ height: "auto", width: "100%" }} />
        <Typography variant="h3" color="primary">
          {t("appMaintenance.underMaintenance")}
        </Typography>
        <Typography variant="subtitle1" py={{ xs: 0, sm: 1, md: 2 }}>
          {t("appMaintenance.secondaryNote")}
        </Typography>
        <Typography variant="body1">
          {t("appMaintenance.backShortly")}
        </Typography>
        <Box>
          <Button
            endIcon={<Refresh />}
            sx={{ mt: 2 }}
            color="primary"
            onClick={() => window.location.reload()}
          >
            {t("button.refresh")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AppMaintenance
