import { LoaderFunctionArgs, RouteObject, redirect } from "react-router-dom"
import { GetGameTitle } from "@/features/game/Game"
import { store } from "@/app/store"
import { GameIdParams } from "@/pages/Games/Submission/route"
import customEventsRoute from "@/pages/Games/CustomEvents/route"
import { lazy } from "react"
import i18next from "i18next"

const GameDetails = lazy(
  () => import("@/pages/Games/GameDetails/GameDetailsIndex"),
)

const route: RouteObject = {
  path: "/games/:gameId",
  handle: {
    crumb: (data: string) =>
      data ?? i18next.t("label.gameDetails", { ns: "games" }),
    pageTitle: () =>
      `${i18next.t("label.gameDetails", { ns: "games" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
  },
  loader: async ({ params }: LoaderFunctionArgs<GameIdParams>) => {
    let title = null
    if (!isNaN(params.gameId)) {
      const parsedParams = { id: parseInt(params.gameId) }
      const getter = store.dispatch(GetGameTitle.initiate(parsedParams))
      await getter
      const response = GetGameTitle.select(parsedParams)(store.getState())

      if (
        (response.data?.game?.length ?? 0) > 0 &&
        response.data!.game![0].name
      ) {
        title = response.data!.game![0].name

        getter.unsubscribe()
      }
    }

    if (title) {
      return title
    } else {
      return redirect("/games")
    }
  },
  children: [
    {
      index: true,
      element: <GameDetails />,
    },
    ...customEventsRoute,
  ],
}

export default route
