import { api as generatedApi } from "./fileManagement.generated"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    "MainFolderId",
    "Folder",
    "File",
    "STORAGE_SPACE",
    "FolderAndFile",
    "SearchFolderAndFile",
    "AdminRootFolderList",
  ],
  endpoints: {
    GetMainFolderId: {
      providesTags: ["MainFolderId"],
    },
    GetFolderList: {
      providesTags: () => [{ type: "Folder", id: "LIST" }],
    },
    GetFolderTitle: {
      providesTags: (result, error, arg) => [{ type: "Folder", id: arg.id }],
    },
    GetFileList: {
      providesTags: () => [{ type: "File", id: "LIST" }],
    },
    GetStorageSpace: {
      providesTags: () => [{ type: "STORAGE_SPACE", id: "LIST" }],
    },
    FolderAndFileById: {
      providesTags: ["FolderAndFile"],
    },
    SearchFolderAndFile: {
      providesTags: ["SearchFolderAndFile"],
    },
    GetAdminRootFolderList: {
      providesTags: ["AdminRootFolderList"],
    },
  },
})

export const {
  useGetMainFolderIdQuery,
  useGetFolderListQuery,
  useGetFolderTitleQuery,
  useGetFileListQuery,
  useGetStorageSpaceQuery,
  useFolderAndFileByIdQuery,
  useSearchFolderAndFileQuery,
  useGetAdminRootFolderListQuery,
} = api

export const {
  GetMainFolderId,
  GetFolderList,
  GetFolderTitle,
  GetFileList,
  GetStorageSpace,
  FolderAndFileById,
} = api.endpoints
