import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const GameSDKstep = lazy(
  () => import("@/pages/Games/Submission/GameSDK/GameSDK"),
)

export type GameIdParams = {
  game_id: string
}

const route: RouteObject = {
  path: "/games/submission/:gameId/sdk",
  handle: {
    crumb: () => i18next.t("label.gameSdk", { ns: "games" }),
    pageTitle: () =>
      `${i18next.t("label.gameSdk", { ns: "games" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
  },
  element: <GameSDKstep />,
}

export default route
