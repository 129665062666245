import { useAuth } from "@/app/hooks"
import { PropsWithChildren, useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"

export default function GoogleAnalyticsProvider({
  children,
}: PropsWithChildren) {
  const { scope } = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (!scope || scope === "external") {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: document.title,
      })
    }
  }, [location, scope])

  return children
}
