import baseTheme from "@/theme/variant/base"
import { createTheme } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const purple = createTheme(
  merge(cloneDeep(baseTheme), {
    palette: {
      theme: "purple",
      primary: {
        main: "#A493ED",
        contrastText: "#1E1E1E",
      },
      bgSvgShade: {
        startColor: "#A493ED",
        stopColor: "#C9BDF5",
      },
      ctaSvgShade: {
        startColor: "#A493ED",
        stopColor: "#C9BDF5",
      },
    },
  }),
)

export default purple
