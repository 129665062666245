import App from "@/App"
import Notifier from "@/components/Notifier/Notifier"
import { Theme } from "@/theme"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/unbounded/400.css"
import "@fontsource/unbounded/600.css"
import "@fontsource/unbounded/700.css"
import "@fontsource/noto-naskh-arabic"
import "@fontsource/noto-naskh-arabic/500.css"
import "@fontsource/noto-naskh-arabic/600.css"
import "@fontsource/noto-naskh-arabic/700.css"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, storePersistor } from "./app/store"

import * as Sentry from "@sentry/react"

import "@/app/i18n"

window.addEventListener("vite:preloadError", (event) => {
  console.log("event", event)
  window.location.reload()
})

if (import.meta.env.VITE_SENTRY_DSN?.trim()) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],
    normalizeDepth: 5,
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/[A-Za-z]+-publishing-portal-backend\.kwalee\.com\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Suspense>
      <Provider store={store}>
        <PersistGate loading={null} persistor={storePersistor}>
          <Theme>
            <Notifier>
              <App />
            </Notifier>
          </Theme>
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
)
