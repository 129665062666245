import { SupportedLanguagesType } from "@/constants/i18n"
import { Theme } from "@mui/material"

const Tooltip: NonNullable<Theme["components"]>["MuiTooltip"] = {
  defaultProps: {
    enterTouchDelay: 0,
  },
}

const TooltipAr: NonNullable<Theme["components"]>["MuiTooltip"] = {
  defaultProps: {
    enterTouchDelay: 0,
  },
  styleOverrides: {
    tooltip: () => ({
      fontSize: 16,
      fontWeight: 600,
    }),
  },
}

const getTooltipOverrides = (
  locale: SupportedLanguagesType,
): NonNullable<Theme["components"]>["MuiTooltip"] => {
  switch (locale) {
    case "en-GB":
      return Tooltip
    case "ar-SA":
      return TooltipAr
    default:
      return Tooltip
  }
}

export default getTooltipOverrides
