import ProtectedRoute from "@/components/ProtectedRoute"
import articleRoutes from "@/pages/KnowledgeBase/Article/route"
import categoryRoute from "@/pages/KnowledgeBase/Category/route"
import { hasModuleRoutePermission } from "@/util/permissions"
import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const KnowledgeBase = lazy(() => import("@/pages/KnowledgeBase/KnowledgeBase"))
const KnowledgeBaseIndex = lazy(
  () => import("@/pages/KnowledgeBase/KnowledgeBaseIndex"),
)

const route: RouteObject = {
  path: "/knowledge-base",
  element: (
    <ProtectedRoute
      hasPermission={hasModuleRoutePermission("kwalee_academy")}
      redirectTo="/"
    >
      <KnowledgeBase />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () => i18next.t("docTitle", { ns: "knowledgeBase" }),
    title: () => i18next.t("pageTitle", { ns: "knowledgeBase" }),
    crumb: () => i18next.t("pageTitle", { ns: "knowledgeBase" }),
  },
  children: [
    {
      index: true,
      element: <KnowledgeBaseIndex />,
    },
    categoryRoute,
    ...articleRoutes,
  ],
}

export default route
