import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import gameSDKroute from "@/pages/Games/Submission/GameSDK/route"
import gameSDKvalidateRoute from "@/pages/Games/Submission/ValidateSDK/route"
import { GetGameTitle } from "@/features/game/Game"
import { store } from "@/app/store"
import i18next from "i18next"

const SubmissionIndex = lazy(
  () => import("@/pages/Games/Submission/SubmitGame/SubmissionIndex"),
)

const EditGame = lazy(
  () => import("@/pages/Games/Submission/SubmitGame/EditGame"),
)

export type GameIdParams = {
  gameId: string
}

const route: RouteObject = {
  path: "/games/submission",
  children: [
    {
      index: true,
      handle: {
        crumb: () => i18next.t("label.gameSubmission", { ns: "games" }),
        pageTitle: () =>
          `${i18next.t("label.gameSubmission", { ns: "games" })} - ${i18next.t(
            "label.hitseeker",
            { ns: "common" },
          )}`,
      },
      element: <SubmissionIndex />,
    },
    {
      path: "/games/submission/:gameId",
      handle: {
        crumb: (data: string) => data ?? "Unknown Game",
      },
      loader: async ({ params }: LoaderFunctionArgs<GameIdParams>) => {
        let title = null
        if (!isNaN(params.gameId)) {
          const parsedParams = { id: parseInt(params.gameId) }
          const getter = store.dispatch(GetGameTitle.initiate(parsedParams))
          await getter
          const response = GetGameTitle.select(parsedParams)(store.getState())

          if (
            (response.data?.game?.length ?? 0) > 0 &&
            response.data!.game![0].name
          ) {
            title = response.data!.game![0].name

            getter.unsubscribe()
          }
        }

        return title
      },
      children: [
        {
          index: true,
          element: <EditGame />,
          handle: { pageTitle: () => "Edit Game - Hitseeker" },
        },
        gameSDKroute,
        gameSDKvalidateRoute,
      ],
    },
  ],
}

export default route
