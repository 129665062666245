import { TypographyOptions } from "@mui/material/styles/createTypography"

const TypographyEn: TypographyOptions = {
  fontFamily: [
    "Roboto",
    "Unbounded",
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),

  h1: {
    fontSize: 46,
    lineHeight: "70px",
    fontWeight: 700,
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 30,
      lineHeight: "40px",
    },
  },
  h2: {
    fontSize: 36,
    lineHeight: "46px",
    fontWeight: 600,
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 24,
      lineHeight: "36px",
    },
  },
  h3: {
    fontSize: 26,
    lineHeight: "40px",
    fontWeight: 600, //500
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 18,
      lineHeight: "26px",
    },
  },
  h4: {
    fontSize: 22,
    lineHeight: "30px",
    fontWeight: 400,
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
    },
  },
  h5: {
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: 400,
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 13,
      lineHeight: "20px",
      fontWeight: 700,
    },
  },
  h6: {
    fontSize: 16,
    lineHeight: "30px",
    fontWeight: 600,
    fontFamily: "Unbounded",
    "@media (max-width:600px)": {
      fontSize: 11,
      lineHeight: "18px",
      fontWeight: 700,
    },
  },
  body1: {
    fontSize: 16,
    fontWeight: 300,
    "@media (max-width:600px)": {
      fontSize: 12,
    },
  },
  body2: {
    "@media (max-width:600px)": {
      fontSize: 11,
    },
  },
  caption: {
    "@media (max-width:600px)": {
      fontSize: 10,
    },
  },

  subtitle2: {
    fontSize: 20,
  },
  button: {
    fontSize: 12,
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 10,
    },
  },
}

export default TypographyEn
