import { alpha, Theme } from "@mui/material"

const CssBaseline: NonNullable<Theme["components"]>["MuiCssBaseline"] = {
  styleOverrides: (theme) => ({
    body: {
      "::-webkit-scrollbar, & *::-webkit-scrollbar": {
        width: 10,
        height: 10,
        background: "transparent",
      },

      "::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        background: "transparent",
      },

      "::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 12,
        background: alpha(theme.palette.text.primary, 0.07),
        transition: theme.transitions.create(["background-color"], {
          duration: theme.transitions.duration.shortest,
        }),

        "&:hover": {
          background: alpha(theme.palette.text.primary, 0.15),
        },
      },

      "::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
        borderRadius: 12,
        background: "transparent",
      },
    },
  }),
}

export default CssBaseline
