import { useAppDispatch, useAppSelector, useAuth } from "@/app/hooks"
import { selectIsAuthenticated } from "@/features/auth/authSlice"
import { selectTheme, setTheme } from "@/features/general/generalSlice"
import { useGetOrganisationsQuery } from "@/features/userManagement/UserManagement"
import { blue, dark, green, light, orange, purple } from "@/theme/variant"
import { ThemeMode } from "@/theme"
import {
  CssBaseline,
  Theme as ThemeObject,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material"
import React, { PropsWithChildren, useEffect, useMemo } from "react"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl"
import { useTranslation } from "react-i18next"

const rtlCache = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
})

const ltrCache = createCache({
  key: "mui",
})

const Theme = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation()

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  const dispatch = useAppDispatch()
  const selectedTheme = useAppSelector(selectTheme)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const { scope } = useAuth()
  const isInternalUser = scope === "internal"

  const { data: organisationData } = useGetOrganisationsQuery(undefined, {
    skip: isInternalUser || !isAuthenticated,
  })
  const organisation = organisationData?.organisations?.[0]

  useEffect(() => {
    if (organisation?.theme) {
      dispatch(setTheme(organisation.theme as ThemeMode))
    }
  }, [organisation, dispatch])

  useEffect(() => {
    document.dir = i18n.dir()
  }, [i18n])

  const theme = useMemo(() => {
    let theme: ThemeObject
    switch (selectedTheme) {
      case "light":
        theme = light
        break
      case "dark":
        theme = dark
        break
      case "blue":
        theme = blue
        break
      case "purple":
        theme = purple
        break
      case "green":
        theme = green
        break
      case "orange":
        theme = orange
        break
      default:
        theme = prefersDarkMode ? dark : light
        break
    }

    return theme
  }, [selectedTheme, prefersDarkMode])

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={i18n.dir() === "rtl" ? rtlCache : ltrCache}>
        <CssBaseline />
        {children}
      </CacheProvider>
    </ThemeProvider>
  )
}

export default Theme
