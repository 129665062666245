import ErrorIcon from "@/assets/notification_error.svg?react"
import InfoIcon from "@/assets/notification_info.svg?react"
import SuccessIcon from "@/assets/notification_success.svg?react"
import WarningIcon from "@/assets/notification_warning.svg?react"
import { useAppDispatch } from "@/app/hooks"
import { closeSnackbar } from "@/features/notifier/notifierSlice"
import {
  Box,
  IconButton,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { CancelOutlined as CancelIcon } from "@mui/icons-material"
import { CustomContentProps, SnackbarContent, VariantType } from "notistack"
import { CSSProperties, forwardRef, useMemo } from "react"
import { useTranslation } from "react-i18next"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  background: theme.palette.background.paper,
  padding: 0,
  left: theme.spacing(-1),
  top: theme.spacing(-1),
}))

function Icon({
  variant,
  style,
  className,
}: {
  variant: VariantType
  style?: CSSProperties
  className?: string
}) {
  const theme = useTheme()

  switch (variant) {
    case "success":
      return (
        <SuccessIcon
          style={{
            color: theme.palette.success.main,
            ...style,
          }}
          className={className}
        />
      )
    case "error":
      return (
        <ErrorIcon
          style={{
            color: theme.palette.error.main,
            ...style,
          }}
          className={className}
        />
      )
    case "info":
      return (
        <InfoIcon
          style={{
            color: theme.palette.info.main,
            ...style,
          }}
          className={className}
        />
      )
    case "warning":
      return (
        <WarningIcon
          style={{
            color: theme.palette.warning.main,
            ...style,
          }}
          className={className}
        />
      )
    case "default":
    default:
      return null
  }
}

interface NotificationComponentProps extends CustomContentProps {
  title?: string
}

const NotifierComponent = forwardRef<
  HTMLDivElement,
  NotificationComponentProps
>((props, ref) => {
  const { id, message, variant, title: notificationTitle, ...other } = props

  const { t } = useTranslation("common")

  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"))

  const dispatch = useAppDispatch()

  const title = useMemo(() => {
    let newTitle = notificationTitle
    if (!newTitle) {
      switch (variant) {
        case "success":
          newTitle = t("label.success")
          break
        case "error":
          newTitle = t("label.error")
          break
        case "info":
          newTitle = t("label.help")
          break
        case "warning":
          newTitle = t("label.warning")
          break
        case "default":
        default:
          newTitle = t("label.notification")
          break
      }
    }

    return newTitle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTitle, t])

  const handleCloseSnackbar = () => {
    dispatch(closeSnackbar(id))
  }

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Box
        component={Paper}
        position="relative"
        minWidth="100%"
        elevation={24}
        p={2}
      >
        <StyledIconButton onClick={handleCloseSnackbar}>
          <CancelIcon fontSize={isMobileDevice ? "medium" : "small"} />
        </StyledIconButton>
        <Box display="flex" alignItems="center" mb={1} gap={1}>
          <Icon style={{ width: 25 }} variant={variant} />
          <Typography flex={1}>{title}</Typography>
        </Box>
        <Typography fontSize={12}>{message}</Typography>
      </Box>
    </SnackbarContent>
  )
})

export default NotifierComponent
