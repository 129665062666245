import { RootState } from "@/app/store"
import { handleLogout, refresh } from "@/features/auth/authSlice"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice"
import { GraphQLError } from "@/model/response"
import {
  BaseQueryArg,
  BaseQueryEnhancer,
  BaseQueryMeta,
  QueryReturnValue,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import {
  BaseQueryError,
  BaseQueryResult,
} from "@reduxjs/toolkit/src/query/baseQueryTypes"
import { Mutex } from "async-mutex"
import i18next from "i18next"

function isExpiredToken(error: GraphQLError) {
  if (!error.extensions || error.extensions.code !== 401) {
    return false
  }

  switch (error.extensions.param_names.reason) {
    case "Token is expired":
    case "Token is not valid":
      return true
    default:
      return false
  }
}

// Don't allow concurrent refreshes
const mutex = new Mutex()

const withTokenRefresh: BaseQueryEnhancer = (baseQuery) => {
  return async (args: BaseQueryArg<typeof baseQuery>, api, extraOptions) => {
    // Wait for any in-progress refreshes to finish
    await mutex.waitForUnlock()

    type BaseQueryReturnValue = QueryReturnValue<
      BaseQueryResult<typeof baseQuery>,
      BaseQueryError<typeof baseQuery>,
      BaseQueryMeta<typeof baseQuery>
    >

    // Attempt the query
    let result = (await baseQuery(
      args,
      api,
      extraOptions,
    )) as BaseQueryReturnValue

    const error = result.error as GraphQLError
    if (!error) {
      return result
    }

    if (!isExpiredToken(error)) {
      return result
    }

    let state = api.getState() as RootState

    if (!state.auth.authenticationResult?.RefreshToken) {
      api.dispatch(handleLogout())

      return result
    }

    if (mutex.isLocked()) {
      await mutex.waitForUnlock()

      state = api.getState() as RootState
      if (state.auth.authenticationResult) {
        result = (await baseQuery(
          args,
          api,
          extraOptions,
        )) as BaseQueryReturnValue
      }
    } else {
      const release = await mutex.acquire()

      try {
        const refreshResult = await api.dispatch(
          refresh({ token: state.auth.authenticationResult.RefreshToken }),
        )

        if ("error" in refreshResult) {
          api.dispatch(handleLogout())

          api.dispatch(
            enqueueSnackbar({
              message:
                refreshResult.payload === "Invalid Refresh Token"
                  ? i18next.t("common:notifier.sessionExpired")
                  : i18next.t("common:notifier.errorWhileRefreshing"),
              options: {
                key: "login_refresh_error",
                variant: "error",
              },
            }),
          )

          return {
            error: refreshResult.payload,
          }
        }

        result = (await baseQuery(
          args,
          api,
          extraOptions,
        )) as BaseQueryReturnValue
      } finally {
        release()
      }
    }

    return result
  }
}

export default withTokenRefresh
