import { getCurrentLocale } from "@/util/i18n"
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers) => {
    const locale = getCurrentLocale() ?? "en-GB"
    headers.set("hitseeker-locale", locale)

    return headers
  },
})

export default baseQuery
