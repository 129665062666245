import { useAppDispatch, useAppSelector } from "@/app/hooks"
import Loading from "@/components/Loading"
import { logout, selectIsAuthenticated } from "@/features/auth/authSlice"
import {
  enqueueSnackbar,
  closeSnackbar,
} from "@/features/notifier/notifierSlice"
import { useGetLoggedInUserQuery } from "@/features/userManagement/UserManagement"
import { Box } from "@mui/material"
import i18next from "i18next"
import { FC, PropsWithChildren, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import ReactGA from "react-ga4"
import { HITSEEKER_EVENT } from "@/util/hitseekerEvents"

const RESTRICTED_USER_STATUSES = ["inactive", "blocked", "deleted"]

const RESTRICTED_ERROR_MESSAGES: Record<string, string> = {
  inactive: i18next.t("notifier.accountInactive"),
  blocked: i18next.t("notifier.accountBlocked"),
  deleted: i18next.t("notifier.accountDeleted"),
}

const AuthorisationRoute: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation("onboarding")

  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  const {
    data,
    isFetching: isLoadingUser,
    isError,
    error,
  } = useGetLoggedInUserQuery(undefined, {
    skip: !isAuthenticated,
    refetchOnMountOrArgChange: true,
  })
  const loggedInUserData = data?.me

  const isUserRestricted = RESTRICTED_USER_STATUSES.includes(
    loggedInUserData?.status ?? "",
  )
  const isUserUnauthorised = isError || isUserRestricted

  useEffect(() => {
    if (isUserUnauthorised) {
      dispatch(logout(false))
    }

    if (isError) {
      dispatch(closeSnackbar("login_success"))
      dispatch(
        enqueueSnackbar({
          message: t("notifier.loginFailed"),
          options: {
            key: "authorisation_error",
            variant: "error",
          },
        }),
      )
    }

    if (isUserRestricted) {
      dispatch(closeSnackbar("login_success"))
      dispatch(
        enqueueSnackbar({
          message:
            RESTRICTED_ERROR_MESSAGES[loggedInUserData?.status ?? ""] ??
            t("notifier.loginFailed"),
          options: {
            key: "authorisation_error",
            variant: "error",
          },
        }),
      )
    }
  }, [isUserUnauthorised, isUserRestricted, loggedInUserData?.status, dispatch])

  useEffect(() => {
    if (loggedInUserData?.id) {
      const internalUser =
        loggedInUserData?.user_type === "internal_admin" ||
        loggedInUserData?.user_type === "internal_restricted_user"

      if (!internalUser) {
        ReactGA.set({ userId: loggedInUserData.id })
        HITSEEKER_EVENT.identify(
          loggedInUserData.id,
          loggedInUserData.name,
          loggedInUserData.email,
        )
      }
    }
  }, [loggedInUserData?.id])

  if (isUserUnauthorised) {
    console.error(error)

    return null
  }

  if (isLoadingUser) {
    return (
      <Box height="100vh">
        <Loading />
      </Box>
    )
  }

  if (
    loggedInUserData?.user_type === "external_admin" &&
    !loggedInUserData?.organisation_id
  ) {
    return <Navigate to="/organisation-setup" />
  }

  return children
}

export default AuthorisationRoute
