import baseTheme from "@/theme/variant/base"
import { createTheme, ThemeOptions } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const blue = createTheme(
  merge(cloneDeep(baseTheme), {
    palette: {
      theme: "blue",
      primary: {
        main: "#64C0FF",
        light: "#9FDDFF",
        contrastText: "#1E1E1E",
      },
      bgSvgShade: {
        startColor: "#64C0FF",
        stopColor: "#9FDDFF",
      },
      ctaSvgShade: {
        startColor: "#64C0FF",
        stopColor: "#9FDDFF",
      },
    },
  } as ThemeOptions),
)

export default blue
