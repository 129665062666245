import { TypographyOptions } from "@mui/material/styles/createTypography"

const TypographyAr: TypographyOptions = {
  fontFamily: ["Roboto", "Noto Naskh Arabic", "serif"].join(","),

  h1: {
    fontSize: 48,
    lineHeight: "70px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 32,
      lineHeight: "40px",
    },
  },
  h2: {
    fontSize: 38,
    lineHeight: "46px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 26,
      lineHeight: "36px",
    },
  },
  h3: {
    fontSize: 28,
    lineHeight: "40px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  h4: {
    fontSize: 24,
    lineHeight: "30px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
    },
  },
  h5: {
    fontSize: 22,
    lineHeight: "30px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 15,
      lineHeight: "20px",
      fontWeight: 700,
    },
  },
  h6: {
    fontSize: 18,
    lineHeight: "30px",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: 13,
      lineHeight: "18px",
      fontWeight: 700,
    },
  },
  body1: {
    fontSize: 18,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 14,
    },
  },
  body2: {
    fontSize: 18,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 12,
    },
  },
  caption: {
    fontSize: 16,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 11,
    },
  },
  subtitle2: {
    fontSize: 22,
    fontWeight: 500,
  },
  button: {
    fontSize: 14,
    fontWeight: "700 !important",

    "&.MuiButton-sizeSmall": {
      fontSize: 14,
      fontWeight: "700 !important",
    },

    "@media (max-width:600px)": {
      fontSize: 12,
    },
  },
}

export default TypographyAr
