import { lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import i18next from "i18next"
import ProtectedRoute from "@/components/ProtectedRoute"

const ByteBrewForm = lazy(
  () => import("@/pages/Games/CustomEvents/ByteBrewAdd"),
)
const ManageCustomEvents = lazy(
  () => import("@/pages/Games/CustomEvents/ManageCustomEvents"),
)
const ByteBrewGames = lazy(
  () => import("@/pages/Games/CustomEvents/ByteBrewGames"),
)
const CustomEvents = lazy(() => import("@/pages/Games/CustomEvents"))

const route: NonNullable<RouteObject["children"]> = [
  {
    path: "/games/:gameId/custom-events",
    handle: {
      pageTitle: () => i18next.t("docTitle", { ns: "customEvents" }),
      title: () => i18next.t("pageTitle", { ns: "customEvents" }),
      crumb: () => i18next.t("pageTitle", { ns: "customEvents" }),
    },
    element: (
      <ProtectedRoute
        hasPermission={({ auth }) => auth.scope === "internal"}
        redirectTo="/games"
      >
        <CustomEvents />
      </ProtectedRoute>
    ),
  },
  {
    path: "/games/:gameId/manage-custom-events",
    handle: {
      pageTitle: () =>
        i18next.t("label.manageCustomEvents", { ns: "customEvents" }),
      title: () =>
        i18next.t("label.manageCustomEvents", { ns: "customEvents" }),
      crumb: () =>
        i18next.t("label.manageCustomEvents", { ns: "customEvents" }),
    },
    element: (
      <ProtectedRoute
        hasPermission={({ auth }) => auth.scope === "external"}
        redirectTo="/games"
      >
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <ManageCustomEvents />,
      },
      {
        handle: {
          pageTitle: () => i18next.t("docTitle", { ns: "customEvents" }),
          title: () => i18next.t("pageTitle", { ns: "customEvents" }),
          crumb: () => i18next.t("pageTitle", { ns: "customEvents" }),
        },
        path: "/games/:gameId/manage-custom-events/custom-events",
        element: <CustomEvents />,
      },
      {
        handle: {
          pageTitle: () =>
            i18next.t("label.byteBrewGames", { ns: "customEvents" }),
          title: () => i18next.t("label.byteBrewGames", { ns: "customEvents" }),
          crumb: () => i18next.t("label.byteBrewGames", { ns: "customEvents" }),
        },
        path: "/games/:gameId/manage-custom-events/bytebrew",
        children: [
          { index: true, element: <ByteBrewGames /> },
          {
            handle: {
              pageTitle: () =>
                i18next.t("label.addGameToByteBrew", { ns: "customEvents" }),
              title: () =>
                i18next.t("label.addGameToByteBrew", { ns: "customEvents" }),
              crumb: () =>
                i18next.t("label.addGameToByteBrew", { ns: "customEvents" }),
            },
            path: "/games/:gameId/manage-custom-events/bytebrew/add",
            element: <ByteBrewForm />,
          },
        ],
      },
    ],
  },
]
export default route
