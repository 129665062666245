import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import i18next from "i18next"

const Notification = lazy(() => import("@/pages/AppNotifications/Notification"))

const route: RouteObject = {
  path: "/notifications",
  element: <Notification />,
  handle: {
    pageTitle: () => i18next.t("docTitle", { ns: "notification" }),
    title: () => i18next.t("pageTitle", { ns: "notification" }),
    crumb: () => i18next.t("pageTitle", { ns: "notification" }),
  },
}
export default route
