import { setLoggedInUser } from "@/features/auth/authSlice"
import { api as generatedApi } from "./UserManagement.generated"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ["Organisation", "User", "Permission", "InternalRoles"],
  endpoints: {
    GetOrganisations: {
      providesTags: () => [{ type: "Organisation", id: "CURRENT_ORG" }],
    },
    GetOrganisationsList: {
      providesTags: () => [{ type: "Organisation", id: "LIST" }],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    },
    GetUsers: {
      providesTags: () => [{ type: "User", id: "LIST" }],
    },
    GetAllUsersName: {
      providesTags: () => [{ type: "User", id: "LIST" }],
    },
    GetUsersCount: {
      providesTags: () => [{ type: "User", id: "COUNT" }],
    },
    GetAllUsersList: {
      providesTags: () => [{ type: "User", id: "LIST" }],
    },
    GetLoggedInUser: {
      providesTags: () => [{ type: "User", id: "ME" }],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled

        if (data.me) {
          dispatch(
            setLoggedInUser({
              id: data.me.id,
              userType: data.me.user_type,
              permissionsModules: data.me.permission_modules.map(
                (permission) => permission.module,
              ),
            }),
          )
        }
      },
    },
    GetPermissionModules: {
      providesTags: () => [{ type: "Permission", id: "LIST" }],
    },
    UpdateUserPermissions: {
      invalidatesTags: () => [{ type: "User", id: "LIST" }],
    },
    UpdateUserProjects: {
      invalidatesTags: () => [{ type: "User", id: "LIST" }],
    },
    UpdatePermissionModule: {
      invalidatesTags: () => [{ type: "User", id: "LIST" }],
    },
    GetUser: {
      providesTags: () => [{ type: "User", id: "ITEM" }],
    },
    CreateInternalRole: {
      invalidatesTags: () => [{ type: "InternalRoles", id: "LIST" }],
    },
    GetInternalRoles: {
      providesTags: () => [{ type: "InternalRoles", id: "LIST" }],
    },
    ArchiveInternalRole: {
      invalidatesTags: () => [{ type: "InternalRoles", id: "LIST" }],
    },
    GetAllInternalUsersList: {
      providesTags: () => [{ type: "User", id: "LIST" }],
    },
  },
})

export const {
  useGetOrganisationsQuery,
  useGetOrganisationsListQuery,
  useGetUsersQuery,
  useGetLoggedInUserQuery,
  useGetPermissionModulesQuery,
  useUpdateUserPermissionsMutation,
  useUpdateUserProjectsMutation,
  useUpdatePermissionModuleMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetAllUsersListQuery,
  useGetAllUsersNameQuery,
  useGetUsersCountQuery,
  useCreateInternalRoleMutation,
  useGetInternalRolesQuery,
  useArchiveInternalRoleMutation,
  useGetAllInternalUsersListQuery,
} = api

export const {
  GetOrganisations,
  GetOrganisationsList,
  GetUsers,
  GetLoggedInUser,
  GetPermissionModules,
  UpdateUserPermissions,
  UpdateUserProjects,
  UpdatePermissionModule,
  GetUser,
  GetAllUsersList,
} = api.endpoints
