import baseTheme from "@/theme/variant/base"
import { createTheme } from "@mui/material"
import cloneDeep from "lodash.clonedeep"
import merge from "lodash.merge"

const green = createTheme(
  merge(cloneDeep(baseTheme), {
    palette: {
      theme: "green",
      primary: {
        main: "#47CFAF",
        light: "#92EEDB",
        contrastText: "#1E1E1E",
      },
      bgSvgShade: {
        startColor: "#47CFAF",
        stopColor: "#92EEDB",
      },
      ctaSvgShade: {
        startColor: "#47CFAF",
        stopColor: "#92EEDB",
      },
    },
  }),
)

export default green
