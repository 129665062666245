import KwaleeLogoHand from "@/assets/KwaleeLogoHand.svg?react"
import {
  Box,
  Breakpoint,
  Dialog,
  DialogContent,
  keyframes,
  styled,
  Typography,
} from "@mui/material"

const wave = keyframes`
    0% {
        transform: rotate(0.0deg)
    }
    10% {
        transform: rotate(14.0deg)
    }
    20% {
        transform: rotate(-8.0deg)
    }
    30% {
        transform: rotate(14.0deg)
    }
    40% {
        transform: rotate(-4.0deg)
    }
    50% {
        transform: rotate(10.0deg)
    }
    /* Reset for the last half to pause */
    60% {
        transform: rotate(0.0deg)
    }
    100% {
        transform: rotate(0.0deg)
    }
`

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const StyledLogo = styled(KwaleeLogoHand)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.primary.contrastText
      : theme.palette.kwalee.main,
  animation: `${wave} 2.5s infinite`,
  transformOrigin: "70% 70%",
}))

const FadeIn = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  animation: `${fadeIn} 3s`,
})

type LoadingProps = {
  variant?: "primary" | "background"
}

export default function Loading({ variant = "primary" }: LoadingProps) {
  return (
    <Box
      height="100%"
      bgcolor={(theme) =>
        theme.palette.mode === "dark"
          ? theme.palette.background.default
          : variant === "primary"
          ? theme.palette.primary.main
          : theme.palette.background.default
      }
    >
      <FadeIn>
        <StyledLogo />
        <Typography
          variant="subtitle1"
          component="h1"
          color={(theme) =>
            theme.palette.mode === "light"
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main
          }
          mt={2}
          ml={1}
        >
          Loading
        </Typography>
      </FadeIn>
    </Box>
  )
}

export const LoadingDialog = ({
  label = "Uploading...",
  maxWidth = "sm",
}: {
  label?: string
  maxWidth?: false | Breakpoint | undefined
}) => (
  <Dialog
    open={true}
    maxWidth={maxWidth}
    fullWidth
    PaperProps={{
      "data-qa-testid": "loading-dialog",
    }}
  >
    <DialogContent
      sx={{
        minHeight: 300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <StyledLogo />
      <Typography variant="h6" data-qa-testid="loading-label">
        {label}
      </Typography>
    </DialogContent>
  </Dialog>
)
