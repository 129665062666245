import { useAppSelector } from "@/app/hooks"
import Loading from "@/components/Loading"
import AuthorisationRoute from "@/components/AuthorisedRoute"
import ProtectedRoute from "@/components/ProtectedRoute"
import { selectIsAuthenticated } from "@/features/auth/authSlice"
import discussionForumRoute from "@/pages/DiscussionForum/route"
import myOrganisationRoute from "@/pages/MyOrganisation/route"
import allOrganisationsRoute from "@/pages/AllOrganisations/route"
import allUsersRoute from "@/pages/AllUsers/route"
import allInternalUsersRoute from "@/pages/InternalUsers/route"
import profileRoute from "@/pages/Profile/route"
import fileManagementRoute from "@/pages/FileManagement/route"
import gamesRoute from "@/pages/Games/route"
import knowledgeBaseRoute from "@/pages/KnowledgeBase/route"
import analytics from "@/pages/Analytics/route"
import notificationRoute from "@/pages/AppNotifications/route"
import facebookAdAccountRoute from "@/pages/FacebookAdAccount/route"
import homeRoute from "@/pages/Home/route"
import PageTitleProvider from "@/providers/PageTitleProvider"
import GoogleAnalyticsProvider from "@/providers/GoogleAnalyticsProvider"
import SomethingWentWrong from "@/components/SomethingWentWrong"
import { Box } from "@mui/material"
import { lazy, PropsWithChildren, Suspense, useMemo } from "react"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import ReactGA from "react-ga4"
import { getCurrentLocale } from "./util/i18n"
import { useTranslation } from "react-i18next"
import moment from "moment/moment"
import "moment/locale/ar-sa"
import AppMaintenance from "@/components/AppMaintenance"
import { HITSEEKER_EVENT } from "@/util/hitseekerEvents"

const Layout = lazy(() => import("@/components/Layout/Layout"))
const OnboardingLayout = lazy(
  () => import("@/components/Layout/Onboarding/OnboardingLayout"),
)

const Login = lazy(() => import("@/pages/Login/Login"))
const AdminLogin = lazy(() => import("@/pages/Login/AdminLogin"))

const Register = lazy(() => import("@/pages/Register/Register"))
const RegisterSuccess = lazy(() => import("@/pages/Register/RegisterSuccess"))

const Auth = lazy(() => import("@/pages/Auth/Auth"))

const VerifyEmail = lazy(() => import("@/pages/VerifyEmail/VerifyEmail"))
const ForgotPassword = lazy(
  () => import("@/pages/ForgotPassword/ForgotPassword"),
)

const OrganisationSetup = lazy(
  () => import("@/pages/OrganisationSetup/OrganisationSetup"),
)

const HybridSubmission = lazy(
  () => import("@/pages/Games/hybrid-submission/HybridSubmission"),
)

const PageNotFound = lazy(() => import("@/components/NotFound/PageNotFound"))

if (import.meta.env.VITE_ENABLE_GA === "true") {
  ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)
}

if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  HITSEEKER_EVENT.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    track_pageview: "full-url",
    persistence: "localStorage",
  })
}

export default function App() {
  const { t } = useTranslation("common")

  const appMaintenanceRouter = useMemo(() => {
    return createBrowserRouter([
      {
        path: "*",
        element: <AppMaintenance />,
      },
    ])
  }, [])

  const router = useMemo(() => {
    const locale = getCurrentLocale()
    const routerBasePath = locale ? `/${locale}` : undefined

    moment.locale(locale === "ar-SA" ? "ar-sa" : "en")

    return createBrowserRouter(
      [
        {
          element: (
            <PageTitleProvider>
              <GoogleAnalyticsProvider>
                <OnboardingLayout />
              </GoogleAnalyticsProvider>
            </PageTitleProvider>
          ),
          errorElement: <SomethingWentWrong />,
          children: [
            {
              path: "/login",
              handle: {
                pageTitle: () =>
                  `${t("pageTitle.loginPage", {
                    ns: "onboarding",
                  })} - ${t("label.hitseeker", { ns: "common" })}`,
              },
              element: <Login />,
            },
            {
              path: "/register",
              handle: {
                pageTitle: () =>
                  `${t("pageTitle.registerPage", {
                    ns: "onboarding",
                  })} - ${t("label.hitseeker", { ns: "common" })}`,
              },
              element: <Register />,
            },
            { path: "/register-success", element: <RegisterSuccess /> },
            {
              path: "/forgot-password",
              handle: {
                pageTitle: () =>
                  `${t("pageTitle.forgotPasswordPage", {
                    ns: "onboarding",
                  })} - ${t("label.hitseeker", { ns: "common" })}`,
              },
              element: <ForgotPassword />,
            },
            { path: "/set-password", element: <Register onlySetPassword /> },
            { path: "/auth", element: <Auth scope="external" /> },
            { path: "/admin/login", element: <AdminLogin /> },
            { path: "/admin/auth", element: <Auth scope="internal" /> },
            { path: "/verify", element: <VerifyEmail /> },
          ],
        },
        {
          path: "/organisation-setup",
          handle: { pageTitle: () => "Organisation Setup - Hitseeker" },
          errorElement: <SomethingWentWrong />,
          element: (
            <AuthenticatedRoute>
              <PageTitleProvider>
                <GoogleAnalyticsProvider>
                  <OrganisationSetup />
                </GoogleAnalyticsProvider>
              </PageTitleProvider>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/hybrid",
          element: <HybridSubmission />,
        },
        {
          path: "/",
          element: (
            <AuthenticatedRoute>
              <AuthorisationRoute>
                <PageTitleProvider>
                  <GoogleAnalyticsProvider>
                    <Layout />
                  </GoogleAnalyticsProvider>
                </PageTitleProvider>
              </AuthorisationRoute>
            </AuthenticatedRoute>
          ),
          errorElement: <SomethingWentWrong />,
          handle: {
            pageTitle: () => `${t("label.home")} - ${t("label.hitseeker")}`,
            crumb: () => t("label.home"),
          },
          children: [
            homeRoute,
            analytics,
            knowledgeBaseRoute,
            fileManagementRoute,
            discussionForumRoute,
            myOrganisationRoute,
            allOrganisationsRoute,
            profileRoute,
            gamesRoute,
            notificationRoute,
            facebookAdAccountRoute,
            allUsersRoute,
            allInternalUsersRoute,
          ],
        },
        /* Redirection URLS for old portal routes */
        {
          path: "/signup",
          element: <Navigate to="/register" replace={true} />,
        },
        {
          path: "/submit-game/info",
          element: <Navigate to="/register" replace={true} />,
        },
        {
          path: "/coaching",
          element: <Navigate to="/register" replace={true} />,
        },
        {
          path: "/wallet",
          element: <Navigate to="/register" replace={true} />,
        },
        {
          path: "/account",
          element: <Navigate to="/register" replace={true} />,
        },
        /* Redirection URLS end */
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
      {
        basename: routerBasePath,
      },
    )
  }, [t])
  return (
    <Suspense
      fallback={
        <Box height="100vh">
          <Loading />
        </Box>
      }
    >
      <RouterProvider
        router={
          import.meta.env.VITE_SHUTDOWN_APP_FOR_MAINTENANCE === "true"
            ? appMaintenanceRouter
            : router
        }
      />
    </Suspense>
  )
}

function AuthenticatedRoute({ children }: PropsWithChildren) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  return (
    <ProtectedRoute hasPermission={isAuthenticated}>{children}</ProtectedRoute>
  )
}
