import { store } from "@/app/store"
import {
  GetCategoryTitle,
  GetCategoryTitleWithParent,
} from "@/features/knowledgeBase/KnowledgeBase"
import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject, redirect } from "react-router-dom"
import articleRoutes from "../Article/route"
import i18next from "i18next"

export type CategoryParams = {
  category: string
  article: string
}

const KnowledgeBaseCategory = lazy(
  () => import("@/pages/KnowledgeBase/Category/KnowledgeBaseCategory"),
)

const route: RouteObject = {
  path: "/knowledge-base/:category",
  handle: {
    crumb: (data: string) =>
      data ?? i18next.t("label.unknownCategory", { ns: "knowledgeBase" }),
  },
  loader: async ({ params }: LoaderFunctionArgs<CategoryParams>) => {
    let title = null

    // @ts-ignore
    if (!isNaN(params.category)) {
      const parsedParams = { id: parseInt(params.category) }
      const getter = store.dispatch(GetCategoryTitle.initiate(parsedParams))
      await getter

      const response = GetCategoryTitle.select(parsedParams)(store.getState())

      if (
        (response.data?.category?.length ?? 0) > 0 &&
        response.data!.category![0].title
      ) {
        title = response.data!.category![0].title
      }

      getter.unsubscribe()
    }

    if (!title && !isNaN(params.category) && !params.article) {
      let categoryId: string | number | undefined

      const parentCategoryGetter = store.dispatch(
        GetCategoryTitleWithParent.initiate({
          parent_id: parseInt(params.category),
        }),
      )

      const childCategoryGetter = store.dispatch(
        GetCategoryTitleWithParent.initiate({
          id: parseInt(params.category),
          locale: "ar-SA",
        }),
      )

      await Promise.allSettled([parentCategoryGetter, childCategoryGetter])

      const parentCategoryResponse = GetCategoryTitleWithParent.select({
        parent_id: parseInt(params.category),
      })(store.getState())

      const childCategoryResponse = GetCategoryTitleWithParent.select({
        id: parseInt(params.category),
        locale: "ar-SA",
      })(store.getState())

      categoryId =
        parentCategoryResponse.data?.category?.[0]?.id ??
        childCategoryResponse.data?.category?.[0]?.parent_id

      parentCategoryGetter.unsubscribe()
      childCategoryGetter.unsubscribe()

      if (categoryId) {
        return redirect(`/knowledge-base/${categoryId}`)
      }
    }

    return title
  },
  children: [
    {
      index: true,
      element: <KnowledgeBaseCategory />,
    },
    ...articleRoutes,
  ],
}

export default route
