import { SupportedLanguagesType } from "@/constants/i18n"
import { Theme } from "@mui/material"

const InputLabel: NonNullable<Theme["components"]>["MuiInputLabel"] = {}

const InputLabelAr: NonNullable<Theme["components"]>["MuiInputLabel"] = {
  styleOverrides: {
    root: () => ({
      fontSize: 20,
      fontWeight: 600,
    }),
  },
}

const getInputLabelOverrides = (
  locale: SupportedLanguagesType,
): NonNullable<Theme["components"]>["MuiInputLabel"] => {
  switch (locale) {
    case "en-GB":
      return InputLabel
    case "ar-SA":
      return InputLabelAr
    default:
      return InputLabel
  }
}

export default getInputLabelOverrides
