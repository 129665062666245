/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../app/services/graphql/types.generated';

import { api } from '@/app/services/graphql/api';
export type GetOrganisationsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOrganisationsQuery = { __typename?: 'Query', organisations?: Array<{ __typename?: 'OrganisationOutputType', id: string, name: string, logo?: string, theme?: string, email?: string, about?: string, website?: string, creator_id?: number, hs_company_id?: string }> };

export type GetOrganisationsListQueryVariables = Types.Exact<{
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  created_at_range?: Types.InputMaybe<Types.Scalars['String']['input']>;
  internal_favourite?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetOrganisationsListQuery = { __typename?: 'Query', query_count?: number, organisations?: Array<{ __typename?: 'OrganisationOutputType', id: string, name: string, logo?: string, theme?: string, email?: string, about?: string, website?: string, creator_id?: number, hs_company_id?: string, internal_favourite: boolean }> };

export type GetLoggedInUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLoggedInUserQuery = { __typename?: 'Query', me?: { __typename?: 'UserOutputType', id: string, name: string, email: string, status: string, profile_image?: string, user_type: string, designation?: string, organisation_id?: number, completion_percentage?: number, permission_modules: Array<{ __typename?: 'PermissionModuleOutputType', user_id: number, module: string }> } };

export type GetUsersQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  user_ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>>;
}>;


export type GetUsersQuery = { __typename?: 'Query', permission_models?: Array<{ __typename?: 'PermissionModelOutputType', user_id: number, model: string, model_id: number }>, game?: Array<{ __typename?: 'AllGamesOutputType', id?: number, name?: string }>, users?: Array<{ __typename?: 'UserOutputType', id: string, name: string, email: string, status: string, user_type: string, designation?: string, profile_image?: string, organisation_id?: number, organisation?: { __typename?: 'OrganisationOutputType', name: string }, permission_modules: Array<{ __typename?: 'PermissionModuleOutputType', id: string, module: string, access_type: string }>, games: Array<{ __typename?: 'GameOutputType', id: string, name: string }> }> };

export type GetAllUsersListQueryVariables = Types.Exact<{
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  created_at_range?: Types.InputMaybe<Types.Scalars['String']['input']>;
  external_users_only?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  internal_users_only?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  campaign_content?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  campaign_medium?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  campaign_name?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  campaign_source?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  campaign_term?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
}>;


export type GetAllUsersListQuery = { __typename?: 'Query', query_count?: number, users?: Array<{ __typename?: 'UserOutputType', id: string, name: string, email: string, status: string, user_type: string, designation?: string, profile_image?: string, organisation_id?: number, internal_user_role_id?: number, organisation?: { __typename?: 'OrganisationOutputType', name: string }, permission_modules: Array<{ __typename?: 'PermissionModuleOutputType', id: string, module: string, access_type: string }>, games: Array<{ __typename?: 'GameOutputType', id: string, name: string }> }> };

export type GetAllInternalUsersListQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page_size?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page_no?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sort_by?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort_dir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  internal_user_role_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAllInternalUsersListQuery = { __typename?: 'Query', query_count?: number, users?: Array<{ __typename?: 'UserOutputType', id: string, name: string, email: string, status: string, user_type: string, designation?: string, profile_image?: string, organisation_id?: number, internal_user_role_id?: number, permission_modules: Array<{ __typename?: 'PermissionModuleOutputType', id: string, module: string, access_type: string }> }> };

export type GetAllUsersNameQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
  internal_users_only?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAllUsersNameQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'UserOutputType', id: string, name: string, profile_image?: string }> };

export type GetUsersCountQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetUsersCountQuery = { __typename?: 'Query', count?: number };

export type GetPermissionModulesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>>;
}>;


export type GetPermissionModulesQuery = { __typename?: 'Query', permission_modules?: Array<{ __typename?: 'PermissionModuleOutputType', user_id: number, module: string, id: string }> };

export type UpdateUserPermissionsMutationVariables = Types.Exact<{
  user_id: Types.Scalars['Int']['input'];
  modules: Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateUserPermissionsMutation = { __typename?: 'Mutation', update_permission_module_list?: { __typename?: 'UpdatePermissionModuleList', ok?: boolean } };

export type UpdateUserProjectsMutationVariables = Types.Exact<{
  user_id: Types.Scalars['Int']['input'];
  game_ids: Array<Types.InputMaybe<Types.Scalars['Int']['input']>> | Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateUserProjectsMutation = { __typename?: 'Mutation', update_permission_model_list?: { __typename?: 'UpdatePermissionModelList', ok?: boolean } };

export type UpdatePermissionModuleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  access_type?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePermissionModuleMutation = { __typename?: 'Mutation', update_permission_module?: { __typename?: 'UpdatePermissionModule', ok?: boolean } };

export type GetUserQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  organisation_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'UserOutputType', name: string, email: string }> };

export type CreateInternalRoleMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  permission_list: Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CreateInternalRoleMutation = { __typename?: 'Mutation', create_internal_role?: { __typename?: 'CreateInternalRole', ok?: boolean } };

export type ArchiveInternalRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  archived: Types.Scalars['Boolean']['input'];
}>;


export type ArchiveInternalRoleMutation = { __typename?: 'Mutation', update_internal_role?: { __typename?: 'UpdateInternalRole', ok?: boolean } };

export type GetInternalRolesQueryVariables = Types.Exact<{
  is_archived?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetInternalRolesQuery = { __typename?: 'Query', roles?: Array<{ __typename?: 'RoleOutputType', id: string, name: string, archived: boolean, permission_list: Array<string> }> };


export const GetOrganisationsDocument = `
    query GetOrganisations($id: Int, $page_size: Int) {
  organisations(filters: {id: $id}, page_size: $page_size) {
    id
    name
    logo
    theme
    email
    about
    website
    creator_id
    hs_company_id
  }
}
    `;
export const GetOrganisationsListDocument = `
    query GetOrganisationsList($page_size: Int, $page_no: Int, $sort_by: String, $sort_dir: String, $search: String, $created_at_range: String, $internal_favourite: Boolean) {
  organisations(
    filters: {search_name: $search, created_at_range: $created_at_range, internal_favourite: $internal_favourite}
    page_size: $page_size
    page_no: $page_no
    sort_by: $sort_by
    sort_dir: $sort_dir
  ) {
    id
    name
    logo
    theme
    email
    about
    website
    creator_id
    hs_company_id
    internal_favourite
  }
  query_count: organisation_total_objects(
    filters: {search_name: $search, internal_favourite: $internal_favourite, created_at_range: $created_at_range}
  )
}
    `;
export const GetLoggedInUserDocument = `
    query GetLoggedInUser {
  me {
    id
    name
    email
    status
    profile_image
    user_type
    designation
    organisation_id
    completion_percentage
    permission_modules {
      user_id
      module
    }
  }
}
    `;
export const GetUsersDocument = `
    query GetUsers($id: Int, $email: String, $organisation_id: Int, $user_ids: [Int]) {
  permission_models(
    filters: {model: ["game"], permission_type: ["write"], user_id: $user_ids}
  ) {
    user_id
    model
    model_id
  }
  game: all_games {
    id
    name
  }
  users(
    filters: {id: $id, email: $email, organisation_id: $organisation_id}
    sort_by: "created_at"
    sort_dir: "desc"
  ) {
    id
    name
    email
    status
    user_type
    designation
    profile_image
    organisation_id
    organisation {
      name
    }
    permission_modules {
      id
      module
      access_type
    }
    games {
      id
      name
    }
  }
}
    `;
export const GetAllUsersListDocument = `
    query GetAllUsersList($organisation_id: Int, $status: String, $search: String, $page_size: Int, $page_no: Int, $sort_by: String, $sort_dir: String, $created_at_range: String, $external_users_only: Boolean, $internal_users_only: Boolean, $campaign_content: [String], $campaign_medium: [String], $campaign_name: [String], $campaign_source: [String], $campaign_term: [String]) {
  users(
    filters: {organisation_id: $organisation_id, status: $status, search_name: $search, external_users_only: $external_users_only, internal_users_only: $internal_users_only, utm_campaign_content: $campaign_content, utm_campaign_medium: $campaign_medium, utm_campaign_name: $campaign_name, utm_campaign_source: $campaign_source, utm_campaign_term: $campaign_term, created_at_range: $created_at_range}
    sort_by: $sort_by
    sort_dir: $sort_dir
    page_no: $page_no
    page_size: $page_size
  ) {
    id
    name
    email
    status
    user_type
    designation
    profile_image
    organisation_id
    internal_user_role_id
    organisation {
      name
    }
    permission_modules {
      id
      module
      access_type
    }
    games {
      id
      name
    }
  }
  query_count: users_total_objects(
    filters: {organisation_id: $organisation_id, status: $status, search_name: $search, external_users_only: $external_users_only, internal_users_only: $internal_users_only, utm_campaign_content: $campaign_content, utm_campaign_medium: $campaign_medium, utm_campaign_name: $campaign_name, utm_campaign_source: $campaign_source, utm_campaign_term: $campaign_term, created_at_range: $created_at_range}
  )
}
    `;
export const GetAllInternalUsersListDocument = `
    query GetAllInternalUsersList($status: String, $search: String, $page_size: Int, $page_no: Int, $sort_by: String, $sort_dir: String, $internal_user_role_id: Int) {
  users(
    filters: {status: $status, search_name: $search, internal_users_only: true, internal_user_role_id: $internal_user_role_id}
    sort_by: $sort_by
    sort_dir: $sort_dir
    page_no: $page_no
    page_size: $page_size
  ) {
    id
    name
    email
    status
    user_type
    designation
    profile_image
    organisation_id
    internal_user_role_id
    permission_modules {
      id
      module
      access_type
    }
  }
  query_count: users_total_objects(
    filters: {status: $status, search_name: $search, internal_users_only: true, internal_user_role_id: $internal_user_role_id}
  )
}
    `;
export const GetAllUsersNameDocument = `
    query GetAllUsersName($status: String, $internal_users_only: Boolean) {
  users(filters: {status: $status, internal_users_only: $internal_users_only}) {
    id
    name
    profile_image
  }
}
    `;
export const GetUsersCountDocument = `
    query GetUsersCount($status: String) {
  count: users_total_objects(filters: {status: $status})
}
    `;
export const GetPermissionModulesDocument = `
    query GetPermissionModules($id: [Int]) {
  permission_modules(filters: {user_id: $id}) {
    user_id
    module
    id
  }
}
    `;
export const UpdateUserPermissionsDocument = `
    mutation UpdateUserPermissions($user_id: Int!, $modules: [String]!) {
  update_permission_module_list(user_id: $user_id, modules: $modules) {
    ok
  }
}
    `;
export const UpdateUserProjectsDocument = `
    mutation UpdateUserProjects($user_id: Int!, $game_ids: [Int]!) {
  update_permission_model_list(
    model: "game"
    user_id: $user_id
    model_ids: $game_ids
  ) {
    ok
  }
}
    `;
export const UpdatePermissionModuleDocument = `
    mutation UpdatePermissionModule($id: Int!, $user_id: Int, $access_type: String) {
  update_permission_module(
    id: $id
    access_type: $access_type
    user_id: $user_id
    read_permission: true
    write_permission: true
  ) {
    ok
  }
}
    `;
export const GetUserDocument = `
    query GetUser($id: Int, $organisation_id: Int) {
  users(filters: {id: $id, organisation_id: $organisation_id}) {
    name
    email
  }
}
    `;
export const CreateInternalRoleDocument = `
    mutation CreateInternalRole($name: String!, $permission_list: [String]!) {
  create_internal_role(name: $name, permission_list: $permission_list) {
    ok
  }
}
    `;
export const ArchiveInternalRoleDocument = `
    mutation ArchiveInternalRole($id: Int!, $archived: Boolean!) {
  update_internal_role(id: $id, archived: $archived) {
    ok
  }
}
    `;
export const GetInternalRolesDocument = `
    query GetInternalRoles($is_archived: Boolean) {
  roles(
    filters: {archived: $is_archived}
    sort_by: "created_at"
    sort_dir: "desc"
  ) {
    id
    name
    archived
    permission_list
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrganisations: build.query<GetOrganisationsQuery, GetOrganisationsQueryVariables | void>({
      query: (variables) => ({ document: GetOrganisationsDocument, variables })
    }),
    GetOrganisationsList: build.query<GetOrganisationsListQuery, GetOrganisationsListQueryVariables | void>({
      query: (variables) => ({ document: GetOrganisationsListDocument, variables })
    }),
    GetLoggedInUser: build.query<GetLoggedInUserQuery, GetLoggedInUserQueryVariables | void>({
      query: (variables) => ({ document: GetLoggedInUserDocument, variables })
    }),
    GetUsers: build.query<GetUsersQuery, GetUsersQueryVariables | void>({
      query: (variables) => ({ document: GetUsersDocument, variables })
    }),
    GetAllUsersList: build.query<GetAllUsersListQuery, GetAllUsersListQueryVariables | void>({
      query: (variables) => ({ document: GetAllUsersListDocument, variables })
    }),
    GetAllInternalUsersList: build.query<GetAllInternalUsersListQuery, GetAllInternalUsersListQueryVariables | void>({
      query: (variables) => ({ document: GetAllInternalUsersListDocument, variables })
    }),
    GetAllUsersName: build.query<GetAllUsersNameQuery, GetAllUsersNameQueryVariables | void>({
      query: (variables) => ({ document: GetAllUsersNameDocument, variables })
    }),
    GetUsersCount: build.query<GetUsersCountQuery, GetUsersCountQueryVariables | void>({
      query: (variables) => ({ document: GetUsersCountDocument, variables })
    }),
    GetPermissionModules: build.query<GetPermissionModulesQuery, GetPermissionModulesQueryVariables | void>({
      query: (variables) => ({ document: GetPermissionModulesDocument, variables })
    }),
    UpdateUserPermissions: build.mutation<UpdateUserPermissionsMutation, UpdateUserPermissionsMutationVariables>({
      query: (variables) => ({ document: UpdateUserPermissionsDocument, variables })
    }),
    UpdateUserProjects: build.mutation<UpdateUserProjectsMutation, UpdateUserProjectsMutationVariables>({
      query: (variables) => ({ document: UpdateUserProjectsDocument, variables })
    }),
    UpdatePermissionModule: build.mutation<UpdatePermissionModuleMutation, UpdatePermissionModuleMutationVariables>({
      query: (variables) => ({ document: UpdatePermissionModuleDocument, variables })
    }),
    GetUser: build.query<GetUserQuery, GetUserQueryVariables | void>({
      query: (variables) => ({ document: GetUserDocument, variables })
    }),
    CreateInternalRole: build.mutation<CreateInternalRoleMutation, CreateInternalRoleMutationVariables>({
      query: (variables) => ({ document: CreateInternalRoleDocument, variables })
    }),
    ArchiveInternalRole: build.mutation<ArchiveInternalRoleMutation, ArchiveInternalRoleMutationVariables>({
      query: (variables) => ({ document: ArchiveInternalRoleDocument, variables })
    }),
    GetInternalRoles: build.query<GetInternalRolesQuery, GetInternalRolesQueryVariables | void>({
      query: (variables) => ({ document: GetInternalRolesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrganisationsQuery, useLazyGetOrganisationsQuery, useGetOrganisationsListQuery, useLazyGetOrganisationsListQuery, useGetLoggedInUserQuery, useLazyGetLoggedInUserQuery, useGetUsersQuery, useLazyGetUsersQuery, useGetAllUsersListQuery, useLazyGetAllUsersListQuery, useGetAllInternalUsersListQuery, useLazyGetAllInternalUsersListQuery, useGetAllUsersNameQuery, useLazyGetAllUsersNameQuery, useGetUsersCountQuery, useLazyGetUsersCountQuery, useGetPermissionModulesQuery, useLazyGetPermissionModulesQuery, useUpdateUserPermissionsMutation, useUpdateUserProjectsMutation, useUpdatePermissionModuleMutation, useGetUserQuery, useLazyGetUserQuery, useCreateInternalRoleMutation, useArchiveInternalRoleMutation, useGetInternalRolesQuery, useLazyGetInternalRolesQuery } = injectedRtkApi;

