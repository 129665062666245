import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const GameSDKValidate = lazy(
  () => import("@/pages/Games/Submission/ValidateSDK/ValidateSDK"),
)

const route: RouteObject = {
  path: "/games/submission/:gameId/validate",
  handle: {
    pageTitle: () =>
      `${i18next.t("label.sdkValidate", { ns: "games" })} - ${i18next.t(
        "label.hitseeker",
        { ns: "common" },
      )}`,
    crumb: () => i18next.t("label.sdkValidate", { ns: "games" }),
  },
  element: <GameSDKValidate />,
}

export default route
