import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import ProtectedRoute from "@/components/ProtectedRoute"
import folderRoute from "@/pages/FileManagement/Folder/route"
import { hasModuleRoutePermission } from "@/util/permissions"
import i18next from "i18next"

const FileManagement = lazy(
  () => import("@/pages/FileManagement/FileManagement"),
)

const FileManagementIndex = lazy(
  () => import("@/pages/FileManagement/FileManagementIndex"),
)

const route: RouteObject = {
  path: "/file-management",
  element: (
    <ProtectedRoute
      hasPermission={hasModuleRoutePermission("file_management")}
      redirectTo="/"
    >
      <FileManagement />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () => i18next.t("docTitle", { ns: "fileManagement" }),
    title: () => i18next.t("pageTitle", { ns: "fileManagement" }),
    crumb: () => i18next.t("pageTitle", { ns: "fileManagement" }),
  },
  children: [{ index: true, element: <FileManagementIndex /> }, folderRoute],
}
export default route
