import { store } from "@/app/store"
import ProtectedRoute from "@/components/ProtectedRoute"
import {
  GetArticle,
  GetArticleTitleWithParent,
} from "@/features/knowledgeBase/KnowledgeBase"
import { CategoryParams } from "@/pages/KnowledgeBase/Category/route"
import i18next from "i18next"
import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject, redirect } from "react-router-dom"

const getMappedArticlesIds = async (
  article: string,
): Promise<{
  categoryId?: string | number | undefined
  articleId?: string | number | undefined
}> => {
  let categoryId: string | number | undefined
  let articleId: string | number | undefined

  const parentCategoryGetter = store.dispatch(
    GetArticleTitleWithParent.initiate({
      parent_id: parseInt(article),
    }),
  )

  const childCategoryGetter = store.dispatch(
    GetArticleTitleWithParent.initiate({
      id: parseInt(article),
      locale: "ar-SA",
    }),
  )

  await Promise.allSettled([parentCategoryGetter, childCategoryGetter])

  const parentCategoryResponse = GetArticleTitleWithParent.select({
    parent_id: parseInt(article),
  })(store.getState())

  const childCategoryResponse = GetArticleTitleWithParent.select({
    id: parseInt(article),
    locale: "ar-SA",
  })(store.getState())

  articleId =
    parentCategoryResponse.data?.article?.[0]?.id ??
    childCategoryResponse.data?.article?.[0]?.parent_id

  categoryId =
    parentCategoryResponse.data?.article?.[0]?.category?.id ??
    childCategoryResponse.data?.article?.[0]?.category?.parent_id

  parentCategoryGetter.unsubscribe()
  childCategoryGetter.unsubscribe()

  return { articleId, categoryId }
}

export type ArticleParams = CategoryParams & {
  article: string
}

const KnowledgeBaseArticle = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticle"),
)
const KnowledgeBaseArticleView = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleView"),
)
const KnowledgeBaseArticleCreate = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleCreate"),
)
const KnowledgeBaseArticleEdit = lazy(
  () => import("@/pages/KnowledgeBase/Article/KnowledgeBaseArticleEdit"),
)

const route: RouteObject[] = [
  {
    path: "/knowledge-base/:category/create",
    element: (
      <ProtectedRoute
        redirectTo="/"
        hasPermission={(state) => state.auth.scope === "internal"}
      >
        <KnowledgeBaseArticleCreate />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => i18next.t("label.createArticle", { ns: "knowledgeBase" }),
    },
  },
  {
    path: "/knowledge-base/:category/:article/edit",
    element: (
      <ProtectedRoute
        redirectTo="/"
        hasPermission={(state) => state.auth.scope === "internal"}
      >
        <KnowledgeBaseArticleEdit />
      </ProtectedRoute>
    ),
    handle: {
      crumb: () => i18next.t("label.editArticle", { ns: "knowledgeBase" }),
    },
    loader: async ({ params }: LoaderFunctionArgs<ArticleParams>) => {
      let title = null

      // @ts-ignore
      if (!isNaN(params.article)) {
        const parsedParams = { id: parseInt(params.article) }
        const getter = store.dispatch(GetArticle.initiate(parsedParams))
        await getter

        const response = GetArticle.select(parsedParams)(store.getState())

        if (
          (response.data?.article?.length ?? 0) > 0 &&
          response.data!.article![0].title
        ) {
          title = response.data!.article![0].title
        }

        getter.unsubscribe()
      }

      if (!title && !isNaN(params.article)) {
        const { articleId, categoryId } = await getMappedArticlesIds(
          params.article,
        )

        if (categoryId && articleId) {
          return redirect(`/knowledge-base/${categoryId}/${articleId}/edit`)
        }
      }

      return title
    },
  },
  {
    path: "/knowledge-base/:category/:article",
    element: <KnowledgeBaseArticle />,
    handle: {
      crumb: (data: string) =>
        data ?? i18next.t("label.unknownArticle", { ns: "knowledgeBase" }),
    },
    loader: async ({ params }: LoaderFunctionArgs<ArticleParams>) => {
      let title = null

      // @ts-ignore
      if (!isNaN(params.article)) {
        const parsedParams = { id: parseInt(params.article) }
        const getter = store.dispatch(GetArticle.initiate(parsedParams))
        await getter

        const response = GetArticle.select(parsedParams)(store.getState())

        if (
          (response.data?.article?.length ?? 0) > 0 &&
          response.data!.article![0].title
        ) {
          title = response.data!.article![0].title
        }

        getter.unsubscribe()
      }

      if (!title && !isNaN(params.article)) {
        const { articleId, categoryId } = await getMappedArticlesIds(
          params.article,
        )

        if (categoryId && articleId) {
          return redirect(`/knowledge-base/${categoryId}/${articleId}`)
        }
      }

      return title
    },
    children: [
      {
        index: true,
        element: <KnowledgeBaseArticleView />,
      },
    ],
  },
]

export default route
