import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import ProtectedRoute from "@/components/ProtectedRoute"
import i18next from "i18next"

const ManageAdAccount = lazy(
  () => import("@/pages/FacebookAdAccount/ManageAdAccount"),
)

const route: RouteObject = {
  path: "/fb-ad-account",
  element: (
    <ProtectedRoute
      hasPermission={({ auth }) => auth.scope === "internal"}
      redirectTo="/"
    >
      <ManageAdAccount />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () => i18next.t("fb_ad_account.docTitle", { ns: "cpiTest" }),
    title: () => i18next.t("fb_ad_account.title", { ns: "cpiTest" }),
    crumb: () => i18next.t("fb_ad_account.title", { ns: "cpiTest" }),
  },
}
export default route
