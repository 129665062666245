import { api as generatedApi } from "./cpiTest.generated"

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ["CpiList", "RequestChangesList", "CpiVideos", "FbAdAccount"],
  endpoints: {
    GetCpiList: {
      providesTags: () => [{ type: "CpiList", id: "LIST" }],
    },
    GetCpiTestTitle: {
      providesTags: (result, error, arg) => [{ type: "CpiList", id: arg.id }],
    },
    GetCpiFormDetailById: {
      providesTags: (result, error, arg) => [{ type: "CpiList", id: arg?.id }],
    },

    GetCpiDetails: {
      providesTags: (_, __, arg) => [{ type: "CpiList", id: arg?.game_id }],
    },

    GetCpiById: {
      providesTags: (result, error, arg) => [{ type: "CpiList", id: arg?.id }],
    },

    RequestChangesHistory: {
      providesTags: () => [{ type: "RequestChangesList", id: "LIST" }],
    },

    GetCpiVideos: {
      providesTags: ["CpiVideos"],
    },

    GetAdAccounts: {
      providesTags: [{ type: "FbAdAccount", id: "LIST" }],
    },
  },
})

export const {
  useGetCpiListQuery,
  useRequestChangesHistoryQuery,
  useGetCpiFormDetailByIdQuery,
  useGetCpiByIdQuery,
  useGetCpiVideosQuery,
  useGetCpiDetailsQuery,
  useGetAdAccountsQuery,
  useLazyGetCpiListQuery,
} = api

export const { GetCpiTestTitle } = api.endpoints
