import { Theme } from "@mui/material"

const Tabs: NonNullable<Theme["components"]>["MuiTabs"] = {
  styleOverrides: {
    indicator: {
      height: 3,
      borderRadius: 3,
    },
  },
}

export const Tab: NonNullable<Theme["components"]>["MuiTab"] = {
  styleOverrides: {
    root: {
      textTransform: "none",
      fontWeight: 300,
      letterSpacing: 1,

      "&.Mui-selected": {
        color: "black",
        fontWeight: 500,
      },
    },
  },
}

export default Tabs
