import ProtectedRoute from "@/components/ProtectedRoute"
import { hasInternalModuleRoutePermission } from "@/util/permissions"
import i18next from "i18next"
import { lazy } from "react"
import { RouteObject } from "react-router-dom"

const AllOrganisations = lazy(
  () => import("@/pages/AllOrganisations/AllOrganisations"),
)

const route: RouteObject = {
  path: "/organisations",
  element: (
    <ProtectedRoute
      hasPermission={hasInternalModuleRoutePermission("organisation")}
      redirectTo="/"
    >
      <AllOrganisations />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle.organisationsPage", {
        ns: "organisation",
      })} - ${i18next.t("label.hitseeker", { ns: "common" })}`,
    title: () =>
      i18next.t("pageTitle.organisationsPage", {
        ns: "organisation",
      }),
    crumb: () =>
      i18next.t("pageTitle.organisationsPage", {
        ns: "organisation",
      }),
  },
}

export default route
