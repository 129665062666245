import { RootState } from "@/app/store"
import { GraphQLError } from "@/model/response"
import { getCurrentLocale } from "@/util/i18n"
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query"

const baseQuery = graphqlRequestBaseQuery<GraphQLError | undefined>({
  url: `${import.meta.env.VITE_API_URL}/graphql`,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState

    if (!headers.has("Authorization")) {
      const auth = state.auth.authenticationResult

      if (auth && auth.AccessToken && auth.IdToken) {
        headers.set("Authorisation", `Bearer ${auth.AccessToken}`)
        headers.set("X-IDENTITY", auth.IdToken)
      }
    }

    const locale = getCurrentLocale() ?? "en-GB"
    headers.set("hitseeker-locale", locale)

    return headers
  },
  customErrors: (error) => {
    const { response } = error
    if ((response?.errors?.length ?? 0) > 0) {
      return response.errors![0] as unknown as GraphQLError
    }
  },
})

export default baseQuery
