import { lazy } from "react"
import { RouteObject } from "react-router-dom"
import createThreadRoute from "./CreateThread/route"
import ThreadChatRoute from "./ThreadChat/route"
import ProtectedRoute from "@/components/ProtectedRoute"
import { hasModuleRoutePermission } from "@/util/permissions"
import i18next from "i18next"

const DiscussionForum = lazy(
  () => import("@/pages/DiscussionForum/DiscussionForum"),
)
const DiscussionForumIndex = lazy(
  () => import("@/pages/DiscussionForum/DiscussionForumIndex"),
)

const route: RouteObject = {
  path: "/discussion-forum",
  element: (
    <ProtectedRoute
      hasPermission={hasModuleRoutePermission("discussion_forum")}
      redirectTo="/"
    >
      <DiscussionForum />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () => i18next.t("docTitle", { ns: "discussionForum" }),
    title: () => i18next.t("pageTitle", { ns: "discussionForum" }),
    crumb: () => i18next.t("pageTitle", { ns: "discussionForum" }),
  },
  children: [
    { index: true, element: <DiscussionForumIndex /> },
    createThreadRoute,
    ThreadChatRoute,
  ],
}

export default route
