import ProtectedRoute from "@/components/ProtectedRoute"
import { RouteObject } from "react-router-dom"
import { lazy } from "react"
import i18next from "i18next"
import { hasInternalModuleRoutePermission } from "@/util/permissions"

const AllUsers = lazy(() => import("@/pages/InternalUsers/InternalUsers"))

const route: RouteObject = {
  path: "/internal-users",
  element: (
    <ProtectedRoute
      hasPermission={hasInternalModuleRoutePermission("organisation")}
      redirectTo="/"
    >
      <AllUsers />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () =>
      `${i18next.t("pageTitle.internalUsers", {
        ns: "organisation",
      })} - ${i18next.t("label.hitseeker", { ns: "common" })}`,
    title: () =>
      i18next.t("pageTitle.internalUsers", {
        ns: "organisation",
      }),
    crumb: () =>
      i18next.t("pageTitle.internalUsers", {
        ns: "organisation",
      }),
  },
}

export default route
