import { lazy } from "react"
import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import { GetThreadSubject } from "@/features/discussionForum/discussionForum"
import { store } from "@/app/store"
import i18next from "i18next"

const ThreadChatList = lazy(
  () => import("@/pages/DiscussionForum/ThreadChat/ThreadChat"),
)

export type ThreadParam = {
  threadSubject: string
}

const route: RouteObject = {
  path: "/discussion-forum/:threadId",
  handle: {
    crumb: (data: string) =>
      data ?? i18next.t("label.unknownThread", { ns: "discussionForum" }),
  },
  loader: async ({ params }: LoaderFunctionArgs<ThreadParam>) => {
    let title = null
    if (!isNaN(params.threadId)) {
      const parsedParams = { id: parseInt(params.threadId) }
      const getter = store.dispatch(GetThreadSubject.initiate(parsedParams))
      await getter
      const response = GetThreadSubject.select(parsedParams)(store.getState())

      if (
        (response.data?.discussion_forum_thread?.length ?? 0) > 0 &&
        response.data!.discussion_forum_thread![0].subject
      ) {
        title = response.data!.discussion_forum_thread![0].subject

        getter.unsubscribe()
      }
      return title
    }
  },
  children: [{ index: true, element: <ThreadChatList /> }],
}

export default route
